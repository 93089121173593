<template>
    <div class="flex flex-col items-center mt-5">
        <FontAwesomeIcon
            icon="exclamation-triangle"
            class="text-danger"
            size="6x" />
        <div v-if="userCanTryToRetryAfterFailedSubmit">
            <h5 class="text-gray-500 mt-5 text-center">
                Es gab leider ein Problem bei der
                <span v-if="confirmGroupApplication">Ablehnung deines Antrags.</span>
                <span v-else-if="applicationDataId">Übermittlung deiner Daten.</span>
                <span v-else>Beantragung deiner Juleica.</span>
                Bitte versuche es in ein paar Sekunden erneut!
            </h5>
            <BaseButton
                color="primary"
                class="mt-3 w-100"
                @click="submitJuleicaApplication()">
                <span>Erneut versuchen!</span>
            </BaseButton>
            <div
                v-if="submitApplicationError"
                class="text-center mt-5">
                <strong>Folgende Fehler sind aufgetreten:</strong>
                <div>{{ $get(submitApplicationError, 'response', submitApplicationError) }}</div>
                <div class="mt-3">
                    <strong>Bitte kontaktiere uns bei mehrfachen Auftreten des Fehlers.</strong>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="text-gray-500 mt-5 text-center">
                Es gab leider ein Problem bei der
                <span v-if="applicationDataId">Übermittlung deiner Daten.</span>
                <span v-else>Beantragung deiner Juleica.</span>
                Bitte kontaktiere uns.
            </h5>

            <div
                v-if="$get(submitResponse,'errors',[])"
                class="text-center mt-5">
                <strong>Folgende Fehler sind aufgetreten:</strong>
                <div v-for="error in $get(submitResponse,'errors',[])">
                    {{ $t(error[0]) }}
                </div>

                <div
                    class="link mt-3"
                    @click="reEditData">
                    <span>Fehler korrigieren</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import applicationMain from "@/mixins/application/applicationMain";
import applicationFields from "@/mixins/application/applicationFields";

export default {
    name: "StepApplicationSubmitError",
    mixins: [applicationMain, applicationFields],

    methods: {
        reEditData() {
            this.currentStep = 1
            this.$store.commit('application/setApplicationSubmitResponse', null)
        }
    }
}
</script>

<style scoped>

</style>
