<template>
    <li class="py-5">
        <label class="block text-gray-900 dark:text-gray-300 text-sm font-medium leading-6">{{ question }}</label>
        <p v-if="hint">
            <small
                class="mt-2"
                v-html="hint" />
        </p>
        <div class="mb-2">
            <div class="ml-[4%] mt-3">
                <div
                    :id="index"
                    class="mt-6 space-y-1">
                    <div
                        v-for="option in activeOptions"
                        :key="option.index">
                        <div class="flex items-center">
                            <input
                                :id="option.id"
                                v-model="model"
                                :name="question + option.id"
                                type="radio"
                                :value="option.id"
                                class="size-4 border-gray-300 text-primary focus:ring-primary"
                                @change="emitToParent">
                            <label
                                :for="option.id"
                                class="ml-3 block text-sm/6 font-medium text-gray-900">{{ option.question_option }}</label>
                        </div>
                        <BaseInput
                            v-if="option.additional_text_field && model === option.id"
                            v-model="additionalTextFields[option.id]"
                            class="mb-1"
                            :placeholder="'Wenn ' + option.question_option + ' gewählt wurde, bitte hier eintragen'"
                            @change="emitToParent" />
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: "StatisticSelect",
    components: {},
    props: {
        question: {
            type: String
        },
        options: {
            type: Array
        },
        index: {
            type: Number
        },
        hint: {
            type: String
        }
    },
    data() {
        return {
            loading: false,
            model: '',
            additionalTextFields: {}
        }
    },
    computed: {
        activeOptions() {
            return this.options.filter(option => option.status === 'active')
        }
    },
    methods: {
        emitToParent (event) {
            const emitValue = (this.$get(this.additionalTextFields, this.model, '') !== '' ? this.$get(this.additionalTextFields, this.model, '') : this.model)
            this.$emit('childToParent', emitValue, this.index)
        }
    }
}
</script>

<style  scoped>

</style>
