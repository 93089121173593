export default () => ({
    applicationData: {},
    currentStep: 0,
    doneTillStep: 0,
    steps: ["stepEmail", "stepPersonalDetails", "stepEducation", "stepCheck"],

    applicationSubmitLoading: false,
    applicationSubmitResponse: null,
    responseStatusCodesWhichMayAllowARetry: [408, 425, 429, 502, 503, 504, 509],

    submittedFsExtension: {},

    submitApplicationError: null,

    showAnswers: false,
})
