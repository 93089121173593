<template>
    <div
        style="position:relative"
        :class="{'open':openSuggestion}">
        <div class="relative rounded-md shadow-sm">
            <input
                :class="defaultInputClasses"
                type="text"
                :value="modelValue"
                :disabled="disabled"
                @input="updateValue($event.target.value)"
                @keydown.enter.prevent="enter"
                @keydown.down="down"
                @keydown.up="up"
                @focus="focus"
                @blur="blur">
        </div>
        {{ value }}
        <ul
            class="dropdown-menu"
            style="width:100%">
            <li
                v-for="(suggestion, index) in matches"
                :key="index"
                :class="{'active': isActive(index)}"
                @click="suggestionClick(index)">
                {{ suggestion.display }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {

    name: "AutocompleteCity",
    props: {
        modelValue: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        suggestions: {
            type: Array,
            required: true
        },
        'model.geodata_id': {
            type: String
        },
    },

    data() {
        return {
            dirty: false,
            open: false,
            current: -1,
            defaultInputClasses: `block w-full rounded-md py-1.5 border-0 ring-1 ring-inset ring-gray-300 text-gray-900 shadow-sm placeholder:text-gray-400
                             appearance-none rounded w-full py-2 px-3 leading-tight
                             focus:ring-primary focus:ring-2 focus:ring-inset sm:text-sm focus:outline-none focus:shadow-outline
                             disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 disabled:shadow-none disabled:cursor-not-allowed
                             dark:text-gray-200 dark:placeholder:text-gray-500 disabled:dark:bg-gray-800 disabled:dark:text-gray-300 disabled:dark:ring-gray-400 disabled:dark:text-gray-400`
        }
    },

    computed: {
        // Filtering the suggestion based on the input
        matches() {

            if (this.dirty) {
                // if there was minimum 1 input, then only show matches
                return this.suggestions.filter((obj) => {
                    return obj.display.indexOf(this.modelValue) >= 0
                })
            } else {
                // if there is no input show all
                return this.suggestions
            }
        },

        openSuggestion() {
            return this.selection !== '' &&
                this.open === true
        }
    },

    watch: {
        'suggestions': function() {
            this.suggestions.forEach(element => {
                if (element.display === this.modelValue) this.$emit('update:geodata_id', element.id)
            });
        }
    },

    methods: {

        updateValue(value) {
            if (this.open === false) {
                this.open = true
                this.current = -1
            }
            this.dirty = true
            this.$emit('update:geodata_id', 0)
            this.$emit('update:modelValue', value)
        },

        // When enter pressed on the input
        enter() {
            this.$emit('update:modelValue', this.matches[this.current].display)
            this.$emit('update:geodata_id', this.matches[this.current].id)
            this.open = false
        },

        // When up pressed while suggestions are open
        up() {
            if (this.current > 0) {
                this.current--
            }
        },

        // When up pressed while suggestions are open
        down() {
            if (this.current < this.matches.length - 1) {
                this.current++
            }
        },

        focus() {
            this.open = true
            this.dirty = false
        },

        blur() {
            setTimeout(() => this.open = false, 500);
        },

        // For highlighting element
        isActive(index) {
            return index === this.current
        },

        // When one of the suggestion is clicked
        suggestionClick(index) {
            this.$emit('update:modelValue', this.matches[index].display);
            this.$emit('update:geodata_id', this.matches[index].id)
            this.open = false
        }
    }
}
</script>


<style scoped>

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: .25rem;
}

    .open {
        ul.dropdown-menu {
            display: block;
            padding: 0;
            box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.25);
            overflow:hidden;
        }

        li {
            padding: 5px;


            &:hover,
            &.active {
                background-color: $pink;
                color: white;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }

</style>