import entity from '~/lang/de/statistics/entity.js'
import table from '~/lang/de/statistics/table.js'
import helpText from '~/lang/de/statistics/helpText.js'

export default {
    entity,
    table,
    helpText,

    filterType: {
        federalStates: 'Bundesland Filter',
        organisations: 'Träger Filter',
        poOrganisations: 'ÖT Filter',
        ags: 'AGS'
    }
}
