export default {
    async submitJuleicaApplication({commit, state}) {
        let {$axios, $apiRoute} = useNuxtApp()
        let notification = useNotification()

        try {
            commit('setApplicationSubmitLoading', true)

            let formData = new FormData();
            let axiosRequestconfig = {};

            // append applicationData formData
            Object.keys(state.applicationData).map(key => {
                if (key === 'organisation' || key === 'uploadedDocuments') {
                    formData.append(key, JSON.stringify(state.applicationData[key]))
                } else if (key !== 'imageUrl') {
                    formData.append(key, state.applicationData[key])
                }
            })

            axiosRequestconfig.url = $apiRoute('spa.frontend.card.store')
            axiosRequestconfig.method = "post";
            const headers = {'content-type': 'multipart/form-data'}
            axiosRequestconfig.data = formData;

            let response = await $lara.post($apiRoute('spa.frontend.card.store'), {
                body: formData,
                formDataRequest: true
            })

            console.log('response', response);
            commit('setApplicationSubmitResponse', {data: response, status: 200})
            commit('setApplicationSubmitLoading', false)
        } catch (error) {
            console.log('error', error);

            if (state.applicationData['id'] > 0) {
                if (error.response.status === 422) notification.error('Fehler beim Verarbeiten der Eingaben')
            }

            commit('setSubmitApplicationError', error)
            commit('setApplicationSubmitResponse', error.response)
            commit('setApplicationSubmitLoading', false)
        }
    },

    async rejectCard({commit, state}) {
        let {$axios, $apiRoute} = useNuxtApp()
        let notification = useNotification()

        try {
            commit('setApplicationSubmitLoading', true)

            let axiosRequestconfig = {};

            axiosRequestconfig.url = $apiRoute('spa.frontend.card.update')
            axiosRequestconfig.method = "put";
            axiosRequestconfig.data = {token: state.applicationData.token, dob: state.applicationData.dob};

            let response = await $lara.put($apiRoute('spa.frontend.card.update'), {
                body: {token: state.applicationData.token, dob: state.applicationData.dob}
            })

            commit('setApplicationSubmitResponse', {data: response, status: 200})
            commit('setApplicationSubmitLoading', false)
        } catch (error) {

            if (state.applicationData['id'] > 0) {
                if (error.response.status === 422) notification.error('Fehler beim Verarbeiten der Eingaben')
            }

            commit('setApplicationSubmitResponse', error.response)
            commit('setApplicationSubmitLoading', false)
        }
    }
}
