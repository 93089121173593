<template>
    <div>
        <div
            v-if="$get(applicationData,'latestCard',null) !== null && $get(applicationData,'latestCard.newCardApplicationAllowed',true)===false">
            <h1>Hallo {{ $get(applicationData, 'firstname', '') }},</h1>
            <p class="mt-4">
                du hast aktuell einen laufenden Juleica-Antrag unter der Nummer
                {{ $get(applicationData, 'latestCard.application_number', '') }}. Solange dieser Antrag läuft, kannst du
                keinen neuen Antrag erstellen.
            </p>
            <p class="mt-4">
                Alle Daten rund um deinen laufenden Antrag findest du hier:

                <BaseButton
                    class="mt-4"
                    color="primary"
                    size="lg"
                    @click="useRouter().push(localePath('/myjuleica'))">
                    Zu deiner Juleica-Übersicht
                </BaseButton>
            </p>
        </div>

        <div v-else>
            <div v-if="applicationDataId && !confirmGroupApplication">
                <h1 class="mb-5">
                    Juleica-Antrag überarbeiten
                </h1>

                <div v-if="$store.state.application.applicationData.comment_revision">
                    <p>Dein Träger hat dir folgenden Hinweis zur Überarbeitung gegeben:</p>
                    <div class="speech-bubble mb-3">
                        {{ $store.state.application.applicationData.comment_revision }}
                    </div>
                </div>
            </div>
            <div v-else-if="confirmGroupApplication">
                <h1 class="mb-5">
                    Juleica-Antrag bestätigen
                </h1>
            </div>
            <div v-else>
                <h1 class="mb-5">
                    Persönliche Daten
                </h1>
                <p class="mb-5">
                    Bitte gib deine persönlichen Daten zur Beantragung der Juleica ein.<br><br>Nachdem du den Antrag
                    abgeschickt hast, wird dieser geprüft und anschließend zur Druckerei weitergeleitet. Grundsätzlich
                    wird deine hier eingegebene Adresse für den Postversand deiner Juleica verwendet.
                </p>
            </div>
            <VForm
                v-slot="validation"
                ref="formValidatorStep2"
                as="div"
                :keep-values="true">
                <div v-if="!confirmGroupApplication">
                    <p
                        v-if="!showRecent && !applicationDataId"
                        class="mb-6">
                        <small
                            class="underline cursor-pointer"
                            @click="showRecent = true">
                            <span>Du willst deine Juleica verlängern? Dann klicke hier</span>
                        </small>
                    </p>
                    <div v-if="showRecent">
                        <BaseInput
                            v-model="recentNumber"
                            :label="$t('application.recentNumber')"
                            name="recentNumber">
                            <template #inputHelper>
                                <small class="float-right underline cursor-pointer">
                                    <span @click="showRecent = false;">Nummer unbekannt?</span>
                                </small>
                            </template>
                        </BaseInput>
                        <small>Du kannst deinen Folgeantrag auch ohne Nummer stellen. Das System unterscheidet nicht
                            zwischen Erst- und Folgeantrag.</small>
                    </div>
                </div>

                <div class="grid grid-cols-1 gap-2 mt-3">
                    <BaseInput
                        v-model="firstname"
                        :label="$t('application.firstname')"
                        name="firstname"
                        required
                        :rules="{required: true, max: 50}" />
                    <BaseInput
                        v-model="lastname"
                        :label="$t('application.lastname')"
                        name="lastname"
                        required
                        :rules="{required: true, max: 50}" />
                    <BaseInput
                        :label="$t('application.streetNumber')"
                        name="streetNumber"
                        :remove-margin-bottom="true"
                        required
                        :is-required="true">
                        <div class="grid grid-cols-12">
                            <BaseInput
                                v-model="street"
                                name="street"
                                class="col-span-8 pr-1"
                                required
                                :rules="{required: true}" />
                            <BaseInput
                                v-model="housenumber"
                                name="housenumber"
                                class="col-span-4 pl-1"
                                required
                                :rules="{required: true}" />
                        </div>
                    </BaseInput>
                    <BaseInput
                        :label="$t('application.zipCity')"
                        name="zipCity"
                        :remove-margin-bottom="true"
                        required>
                        <div class="grid grid-cols-12">
                            <BaseInput
                                v-model="zipcode"
                                class="col-span-4 pr-1"
                                required
                                :rules="{required: true, digits:5}"
                                name="PLZ" />

                            <BaseInput
                                v-model="city"
                                name="Ort"
                                class="col-span-8 pl-1"
                                required
                                :rules="{required: true}">
                                <AutocompleteCity
                                    v-model="city"
                                    v-model:geodata_id="geodata_id"
                                    :suggestions="cities" />
                                <small>{{ $get(federalStates, $get(cities, "0.federal_state_id", 0) + ".name", '')
                                }}</small>
                            </BaseInput>
                        </div>
                    </BaseInput>
                    <BaseInput
                        v-model="date_of_birth"
                        type="date"
                        :label="$t('application.date_of_birth')"
                        name="date_of_birth"
                        required
                        :rules="{required: true,birthday:[age, minimumAge],valid_date:age}" />

                    <div v-if="loginmode==='mobile'">
                        <BaseInput
                            v-model="mobile"
                            :label="$t('application.mobile')"
                            name="mobile"
                            readonly
                            input-icon-position="left"
                            group>
                            <template #inputHelper>
                                <small
                                    v-if="!applicationDataId"
                                    class="float-right underline cursor-pointer">
                                    <span @click="currentStep = 0">ändern</span>
                                </small>
                            </template>
                            <template #inputIcon>
                                +49
                            </template>
                        </BaseInput>
                        <small v-if="applicationDataId">
                            Deine {{ $t('application.mobile') }} in einem laufenden Juleica-Antrag kann nur durch deinen
                            Träger geändert werden.
                        </small>
                    </div>

                    <div v-if="loginmode==='email'">
                        <BaseInput
                            v-model="applicationData.email"
                            :label="$t('application.email')"
                            name="email"
                            disabled>
                            <template #inputHelper>
                                <small
                                    v-if="!applicationDataId"
                                    class="float-right underline cursor-pointer">
                                    <span @click="currentStep = 0">ändern</span>
                                </small>
                            </template>
                        </BaseInput>
                        <small v-if="applicationDataId">
                            In einem laufenden Juleica-Antrag kann deine {{ $t('application.email') }} nur durch deinen
                            Träger geändert werden.
                        </small>
                    </div>

                    <div v-if="!userHasAccount">
                        <p class="mb-3 mt-4">
                            Bitte wähle ein Passwort für deinen persönlichen Juleica-Bereich. Du kannst dort alle Infos
                            zu deiner Juleica abrufen.
                        </p>
                        <BaseInput
                            v-model="password"
                            :label="$t('application.password')"
                            type="password"
                            name="password"
                            required
                            :rules="{required: true,verify_password:true,min:8}"
                            validation-mode="aggressive" />
                        <PasswordStrengthMeter
                            :model-value="password"
                            :strength-meter-only="true" />
                        <BaseInput
                            v-model="password_confirmation"
                            :label="$t('application.password_confirmation')"
                            type="password"
                            name="password_confirmation"
                            required
                            :rules="{required: true,confirmed:password}"
                            validation-mode="aggressive" />
                    </div>
                </div>


                <div class="mb-3 mt-6">
                    <label class="block text-gray-900 text-sm font-medium leading-6">Bitte wähle ein Bild von dir aus,
                        das in deine Juleica eingedruckt werden soll.*</label>
                    <small>Das Bild muss ein Portrait von dir sein (ähnlich wie ein Passfoto). So wie du das Bild siehst
                        wird es gedruckt.</small>
                </div>
                <div class="relative">
                    <img
                        class="-ml-44 relative opacity-30 w-[120%] md:w-full md:-ml-44"
                        src="~assets/card_clean.svg">
                    <div class="border-none w-28 absolute top-2.5 right-32">
                        <div class="text-center relative -left-2/3">
                            <img
                                :src="imageUrl"
                                class="block">

                            <div
                                v-if="!imageUrl"
                                class="border-primary text-primary border-solid border-8 bg-white py-6 px-1.5">
                                <!--:class="{'image-invalid': !!errors.length}">
                                -->
                                <FontAwesomeIcon
                                    icon="user"
                                    size="5x" />
                            </div>

                            <input
                                v-model="imageUrl"
                                style="display: none">

                            <QuickValidator
                                v-model="imageUrl"
                                rules="required"
                                name="Bild" />

                            <BaseButton
                                class="mt-2.5"
                                color="gray"
                                size="sm"
                                @click="showCropper = true">
                                <span v-if="imageUrl">Bild ändern</span>
                                <span v-else>Bild auswählen</span>
                            </BaseButton>
                        </div>
                    </div>
                    <AvatarCropper
                        v-model="showCropper"
                        :upload-handler="uploadHandler"
                        :labels="{ submit: $t('application.imageCrop'), cancel: $t('application.imageCropCancel')}"
                        :cropper-options="{aspectRatio: 0.8, autoCropArea: 1,viewMode: 1,movable: true,zoomable: true,rotatable: true}" />
                </div>


                <BaseButton
                    v-if="!applicationDataId"
                    :disabled="loading || (validation ? !!Object.keys(validation.errors).length : false)"
                    color="primary"
                    size="lg"
                    class="mt-5 w-full"
                    native-type="submit"
                    @mouseenter="validation ? validation.validate() : null"
                    @click="onSubmit">
                    <span v-if="!loading">{{ $t('application.next') }}</span>
                    <span v-else><FontAwesomeIcon
                        spin
                        icon="circle-notch" /></span>
                </BaseButton>
            </VForm>
        </div>
    </div>
</template>

<script>
import applicationFields from "@/mixins/application/applicationFields";
import federalStates from "@/mixins/federalStates";
import dateConvert from "@/mixins/dateConvert";
import AutocompleteCity from "@/components/global/Inputs/AutocompleteCity";
import AvatarCropper from "vue-avatar-cropper"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "StepPersonalDetails",
    components: {FontAwesomeIcon, AvatarCropper, AutocompleteCity},
    mixins: [applicationFields, federalStates, dateConvert],
    data() {
        return {
            loading: false,
            loadingCity: false,
            submitRequested: false,
            geodata_id: 0,
            cities: [],
            password_confirmation: '',
            showRecent: false,
            showCropper: false,
        }
    },
    computed: {
        minimumAge() {
            return this.applicationDataId ? 15 : 16;
        },
        age() {
            if (this.date_of_birth === null) return ''
            const today = new Date();
            const birthDate = this.parseAndGetDate(this.date_of_birth)

            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) age--;

            return isNaN(age) ? '' : age;
        },
    },
    watch: {
        'zipcode': function () {
            this.loadCity();
        }
    },

    mounted() {
        if (this.$route.query.expid) {
            this.showRecent = true
            if (!this.recentNumber) this.recentNumber = this.$route.query.expid
        }

        if (this.$route.query.followUpInvalid) {
            this.showRecent = true
            if (!this.recentNumber) this.recentNumber = this.$get(this.applicationData, 'latestCard.card_number', null)
        }

        this.loadCity();

        // show recent juleica numberr directly on edit and if the number is set...
        if (this.applicationDataId && this.recentNumber) this.showRecent = true;

        // only relevant for displaying server-side validation messages when editing and resubmitting changes
        if (this.submitResponse !== null && this.submitResponse.errors !== undefined) this.$refs.formValidatorStep2.setErrors(this.submitResponse.errors);
    },
    methods: {
        async onSubmit() {

            this.loading = true

            // check if loadingCity is finished...
            if (this.loadingCity) {
                this.submitRequested = true
                return
            }

            this.federal_state_id = this.$get(this.cities, "0.federal_state_id", 0)

            if (this.federal_state_id > 0) {
                this.loading = false
                this.$emit('nextPage')
            } else {
                this.loading = false;
                this.$refs.formValidatorStep2.setErrors({zipcode: ['Ungültige PLZ']});
            }
        },

        async loadCity() {
            this.cities = [];
            if (this.zipcode) {
                if (this.zipcode.length === 5) {
                    this.loadingCity = true
                    const {data} = await this.$axios.get(this.$apiRoute('spa.geodatas.index'), {
                        params: {
                            'zipcode': this.zipcode,
                            'mode': 'zipcodeSelect'
                        },
                    });
                    this.cities = data
                    this.loadingCity = false

                    // if submit is requested, send submit!
                    if (this.submitRequested) this.onSubmit()
                }
            }
        },

        uploadHandler(cropper) {
            const canvas = cropper.getCroppedCanvas()
            this.imageUrl = canvas.toDataURL('image/jpeg')
            canvas.toBlob((blob) => {
                this.imageBlob = blob;
            })
        },
    }
}
</script>

<style scoped>

.juleica-card {
    position: relative;

    .juleica-image {
        margin-left: -255px;
        position: relative;
        filter: opacity(0.3);
        width: 120%;

        @media (min-width: 420px) {
            margin-left: -180px;
            width: 100%;
        }
    }

    .card-profileimage {
        border: none;
        width: 7rem;
        position: absolute;
        top: 20px;
        right: 125px;
        position: absolute;

        .card-profileimage-inner {
            position: relative;
            left: -65%
        }

        img {
            display: block;

        }

        button {
            margin-top: 10px;
        }

        .placeholder {

            border-color: $primary;
            color: $primary;
            border-style: solid;
            border-width: 10px;
            background: $white;
            padding: 25px 5px 25px 5px;

        }

        .image-invalid {
            border-color: #dc3545;
            color: #dc3545;
        }

        .invalid-feedback {
            display: block;
            font-size: 100%;
            position: absolute;
            right: -130px;
            top: 0;

        }
    }
}
</style>
