import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
    if (process.client) {
        const api = axios.create({
            baseUrl: "//" + window.location.host,
            defaults: {
                withCredentials: true
            },
            headers: {
                common: {}
            },
        });

        nuxtApp.vueApp.provide('axios', api);

        return {
            provide: {
                axios: api
            }
        };
    }
});