<template>
    <div class="flex flex-col sm:flex-row mb-8 md:mb-0">
        <div class="w-full sm:w-2/6 sm:pr-3 pr-0">
            <BaseInput
                v-model="valueFrom"
                type="date"
                class="mb-0 w-full"
                :rules="{required: true}"
                name="von"
                label="von" />
        </div>
        <div class="w-full sm:w-2/6 sm:pr-3 pr-0">
            <BaseInput
                v-model="valueTo"
                type="date"
                class="mb-0 w-full"
                :rules="{required: true}"
                name="bis"
                label="bis" />
        </div>
        <div>
            <BaseButton
                v-show="!displayFilter"
                :disabled="isNotValid"
                class="mt-4 md:mt-7 h-9"
                color="gray"
                @click="addFilter">
                Filtern
            </BaseButton>
        </div>
    </div>
</template>

<script>
export default {
    name: "TableFilterStatisticBetweenDate",
    props: {
        caption: {
            type: String,
            default: 'Abgeschlossen'
        },
        entityName: {
            type: String,
            required: true
        },
        column: {
            type: String,
            default: 'ready_at'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        currentFilter: {
            type: Array,
            default: []
        }
    },

    data() {
        return {
            valueFrom: '',
            valueTo: '',
            currentFilterIndex: null,
        }
    },
    computed: {
        // is filter already in use? if yes, show filter only if multiple option = true
        displayFilter: function () {
            return this.$get(this.currentFilterActiveValue, [0], '') === this.valueFrom && this.$get(this.currentFilterActiveValue, [1], '') === this.valueTo
        },
        isNotValid() {
            let notValid = false
            if (this.valueFrom > this.valueTo) notValid = 'Ungültiger Zeitraum'
            if (this.valueFrom === '' || this.valueTo === '') notValid = 'Beide Felder müssen ausgefüllt sein'
            return notValid
        },
        currentFilterActiveValue: function () {
            let currentFilterValue = null;
            this.currentFilter.forEach((value, key) => {
                if (value.name === this.$options.name) {
                    currentFilterValue = value.value.split(';');
                    this.currentFilterIndex = key
                }
            });

            this.valueFrom = this.$get(currentFilterValue, [0], '')
            this.valueTo = this.$get(currentFilterValue, [1], '')

            return currentFilterValue;
        }
    },
    methods: {
        addFilter() {
            const formatValueFrom = (this.valueFrom !== "") ? this.$dayjs().format(this.valueFrom) : ""
            const formatValueTo = (this.valueTo !== "") ? this.$dayjs().format(this.valueTo) : ""

            this.$emit('updateFilter', [{
                "name": this.$options.name,
                "type": "statisticBetweenDate",
                "column": this.column,
                "operation": "betweenDate",
                "value": formatValueFrom + ";" + formatValueTo,
                "label": null
            }, this.currentFilterIndex])
        }
    },
}
</script>

<style lang="scss" scoped>


</style>
