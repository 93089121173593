export default {
    stepEmail: "E-Mail",
    stepPersonalDetails: "Persönliche Daten",
    stepEducation: "Träger und Ausbildung",
    stepCheck: "Zusammen&shy;fassung",

    next: 'Weiter',
    back: 'Zurück',
    email: "E-Mail",
    password: "Passwort",
    password_confirmation: "Passwort wiederholen",

    recentNumber: 'Nummer deiner abgelaufenen Juleica',
    unknownOrganisationMail:'E-Mail-Adresse deiner Kontaktperson',
    firstname: 'Vorname',
    lastname: 'Nachname',
    streetNumber: 'Straße und Hausnummer',
    street: 'Straße',
    housenumber: 'Hausnummer',
    zipCity: 'Plz und Ort',
    zip: 'Plz',
    zipcode: 'PLZ',
    city: 'Stadt',
    date_of_birth: 'Geburtstag',
    mobile: 'Handynummer',

    image: 'Bild',
    imageCrop: 'Übernehmen',
    imageCropCancel: 'Abbrechen',

    educationDescription: 'Ausbildungsbeschreibung'
}
