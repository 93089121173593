// import users from '~/lang/de/users'
import application from "./application";
import statistics from "./statistics/main";
import countries from "./countries";
import user from "./user";
import fsExtension from "./fsExtension/main";
import statistic from "./statistic";

export default {
    statistics,
    application,
    countries,
    user,
    fsExtension,
    statistic,

    localeHint: 'Dies ist die deutsche Version der Website.',
    canNotAccessRoute: 'Sie haben keine Berechtigung, auf diese Seite zu gehen.',

    serverError: 'Es liegt serverseitig ein Fehler vor.',
    serverErrorSupport: 'Falls sich dieser Fehler wiederholt wenden Sie sich bitte an den Support.',

    invalidRequestData: 'Die Anfrage enthält ungültige Daten.',
    modelDoesNotExist: 'Die angeforderte Ressource existiert nicht.',
    unauthorizedRequest: 'Sie sind nicht berechtigt, diese Seite aufzurufen.',

    baseData: 'Daten',

    resourceDoesNotExist:
        'Der aufgerufene Eintrag existiert nicht, oder Sie haben keine Berechtigung diesen abzurufen.',

    abort: 'Abbrechen',
    delete: 'Löschen',
    back: 'Zurück',
    save: 'Speichern',
    discard: 'Verwerfen',
    error: 'Fehler',
    open: 'Öffnen',
    show: 'Anzeigen',
    more: 'Weiteres',
    change: 'Ändern',
    add: 'Hinzufügen',
    welcome: 'Willkommen!',

    locales: {
        de: 'Deutsch',
        en: 'English'
    },

    menu: {
        home: 'Home',
        dashboard: 'Dashboard',
        users: 'Nutzer',
        organisations: 'Organisationen',
        settings: 'Einstellungen',
        myProfile: 'Mein Profil',
        information: 'Informationen',
        logout: 'Logout',
        loginProviders: 'Login Methoden'
    },

    files: {
        upload: 'Datei hochladen',
        orDragAndDrop: 'oder hierher ziehen'
    },

    globalSearch: {
        noResults: 'Keine Ergebnisse',
        showAll: 'Alle {count} anzeigen',
        everything: 'Alles',
        defaultPlaceholder: 'Durchsuche alles',
        modulePlaceholder: 'Durchsuche {module}'
    },

    requestLog: {
        title: 'Ergebnis',
        actionsExecuted: 'Es wurde kein Ereignis festgehalten|Es wurde ein Ereignis festgehalten|Es wurden {count} Ereignisse festgehalten',

        type_default_action: "Keine Aktion ausgeführt|Eine Aktion ausgeführt|{count} Aktionen ausgeführt",
        type_user_deleted: "Kein Nutzer gelöscht|Ein Nutzer gelöscht|{count} Nutzer gelöscht",
        type_user_not_deletable: "Kein Nutzer konnte nicht gelöscht werden|Ein Nutzer konnte nicht gelöscht werden|{count} Nutzer konnten nicht gelöscht werden"
    },

    table: {
        overview: 'Übersicht',
        totalEntries: 'Keine Ergebnisse |  Ein Eintrag wurde gefunden | Insgesamt wurden {count} Einträge gefunden',
        labelPerPageOptions: 'Einträge pro Seite',

        actions: {
            filters: 'Filter',
            create: 'Erstellen',
            edit: 'Bearbeiten',
            tools: 'Werkzeuge'
        },

        tools: {
            selected: 'Ausgewählt',
            general: {
                export: {
                    title: 'Exportieren'
                }
            },
            loginProviders: {
                create: {
                    title: 'Login Methode erstellen',
                    provider: 'Anbieter',
                    client_id: 'Client ID',
                    client_secret: 'Client Secret',
                    redirect: 'URL',
                    tenant_id: 'Tenant ID',

                    createProvider: 'Anbieter hinzufügen',
                    deleteProvider: 'Anbieter löschen',

                    noOrganisationFound: 'Es konnte keine Organisation gefunden werden.',
                    providerAlreadyExists: 'Es existiert bereits eine Login Method zu diesem Anbieter.',
                    providerNotDeletable: 'Dieser Anbieter kann nicht gelöscht werden.',
                    createProviderSuccess: 'Der Anbieter wurde erfolgreich erstellt.',
                    deleteProviderSuccess: 'Der Anbieter wurde erfolgreich gelöscht.',
                    providers: {
                        google: 'Google',
                        azure: 'Microsoft'
                    }
                }
            },
            users: {
                delete: {
                    title: 'Löschen',
                    deleteAction: 'Keinen Nutzer löschen | {count} Nutzer löschen | {count} Nutzer löschen',
                    reallyDelete: 'Möchten Sie die ausgewählten Nutzer wirklich löschen?',
                    deletedUsers: 'Die Nutzer wurden gelöscht.'
                },
                create: {
                    title: 'Nutzer erstellen',
                    firstname: 'Vorname',
                    lastname: 'Nachname',
                    email: 'E-Mail',
                    password: 'Passwort',
                    passwordRepeat: 'Passwort wiederholen',
                    createUser: 'Nutzer erstellen',
                    createAndEditUser: 'Erstellen und weiter bearbeiten',
                    createUserSuccess: 'Der Nutzer wurde erfolgreich erstellt.',
                    createUserError: 'Der Nutzer konnte nicht erstellt werden.'
                }
            },
            organisations: {
                create: {
                    title: 'Neue Organisation anlegen',
                    name: 'Name',
                    email: 'E-Mail',
                    createOrganisation: 'Neue Organisation anlegen',
                    createOrganisationSuccess: 'Die Organisation wurde erfolgreich erstellt.',
                    createOrganisationError: 'Die Organisation konnte nicht erstellt werden.'
                },
                toggle: {
                    title: 'Organisationsstatus ändern',
                    toggleText: 'Möchten Sie die ausgewählten Organisationen aktivieren / deaktivieren?',
                    activate: 'Aktivieren',
                    deactivate: 'Deaktivieren'
                }
            }
        },

        filter: {
            filters: 'Filter',
            filter: 'Filter',
            clearAll: 'Filter zurücksetzen',
            yes: 'Ja',
            no: 'Nein',

            operator: {
                contains: 'enthält',
                equals: 'ist gleich',
                startsWith: 'beginnt mit',
                endsWith: 'endet mit',
                greaterThan: 'ist größer als',
                lessThan: 'ist kleiner als',
                greaterThanOrEqual: 'ist größer oder gleich',
                lessThanOrEqual: 'ist kleiner oder gleich',
                notEquals: 'ist nicht gleich'
            }
        }
    },

    settings: {
        language: 'Sprache',
        organisation: 'Organisation',
        roles: 'Rollen'
    },

    auth: {
        login: 'Login',
        logout: 'Logout',
        signIn: 'Anmelden',
        stayLoggedIn: 'Angemeldet bleiben',
        forgotPassword: 'Passwort vergessen?',
        registerHere: 'Hier registrieren',
        noAccountQuestion: 'Noch kein Account?',
        pleaseWait: 'Bitte warten',
        register: 'Registrieren',
        registerText: 'Noch keinen Account?',
        applicationLink: 'Jetzt Juleica beantragen',

        sessionTimeout: 'Ihre Sitzung ist abgelaufen.',
        pleaseLoginAgain: 'Bitte melden Sie sich erneut an.',

        email: 'E-Mail',
        password: 'Passwort',
        repeatPassword: 'Passwort wiederholen',
        savePassword: 'Passwort speichern',
        pleaseEnterNewPassword: 'Bitte geben Sie ein neues Passwort ein.',
        firstname: 'Vorname',
        lastname: 'Nachname',
        back: 'Zurück',
        forgotPasswordText:
            'Bitte gebe deine E-Mail-Adresse ein. Wir senden dir dann einen Link zum Zurücksetzen deine Passworts zu.',
        forgotPasswordSuccess: 'Es wurde eine E-Mail zum Zurücksetzen des Passworts an deine E-Mail Adresse gesendet.',
        resetPassword: 'Passwort zurücksetzen',
        resetPasswordSuccessTitle: 'Dein Passwort wurde erfolgreich geändert',
        resetPasswordSuccessText: 'Du kannst dich jetzt mit dem neuen Passwort anmelden.',
        socialRedirecting: 'Sie werden weitergeleitet...',
        socialError: 'Bei der Anmeldung ist ein Fehler aufgetreten.',
        orContinueWith: 'oder weiter mit'
    },

    users: {
        userId: 'Nutzer {id}',
        newUser: 'Neuer Nutzer',
        createUser: 'Neuen Nutzer anlegen',

        deleteUser: 'Account löschen',
        deleteUserText:
            'Möchtest du den Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        deleteOtherUserText:
            'Möchtest du den Account von {name} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        deleteOwnUserText:
            'Möchtest du deinen Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        deleteUserSuccess: 'Nutzer wurde erfolgreich gelöscht.',
        deleteUserError: 'Der Nutzer konnte nicht gelöscht werden.',
        
        impersonateSuccess: 'Erfolgreich als gewählter Nutzer unterwegs.',
        impersonateError: 'Identität konnte nicht übernommen werden.',
        impersonateBanner: 'Als {fullname} {organisation_name} unterwegs.',
        leaveImpersonationSuccess: 'Identität wurde erfolgreich zurückgesetzt.',
        leaveImpersonationError: 'Identität konnte nicht zurückgesetzt werden.',
        leaveImpersonationLink: 'Zurück',
        updateSuccess: 'Nutzer wurde erfolgreich gespeichert',
        welcome: 'Willkommen {name}!',

        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail',
        profileImage: 'Profilbild',
        profile_image_id: 'Profilbild ID',
        password: 'Passwort',
        passwordRepeat: 'Passwort wiederholen',

        info: {
            general: 'Allgemeine Informationen',
            currentVersion: 'Aktuelle Version',
            currentFeatures: 'Neueste Funktionen',
            bugfixes: 'Bugfixes',
            isCurrentVersion: 'Aktuellste Version',
            loadNewestVersion: 'Neueste Version laden',
            isOldVersion: 'Veraltete Version',
            releaseNotes: 'Release Notes'
        },

        table: {
            title: 'Nutzer',
            email: 'E-Mail',
            customName: 'Anzeigename',
            firstname: 'Vorname',
            role: 'Rolle',
            role_name: 'Rolle',
            created_at: 'Erstellt am',
            id: 'ID',
            impersonate: 'Support geben',

            qf_onlyEmployees: 'Nur Mitarbeiter'
        },
        menu: {
            userBaseData: 'Daten',
            userActivities: 'Historie',
            userCommunications: 'Kommunikation'
        },
        roles: {
            user: 'Mitarbeiter',
            manager: 'Vertriebsleiter',
            admin: 'Administrator'
        }
    },

    organisations: {
        organisationId: 'Organisation {id}',

        name: 'Name',
        email: 'E-Mail',
        membersCount: 'Mitglieder',

        deleteOrganisation: 'Organisation löschen',
        deleteOrganisationText:
            'Möchtest du die Organisation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        deleteOrganisationSuccess: 'Organisation wurde erfolgreich gelöscht.',
        deleteOrganisationError: 'Die Organisation konnte nicht gelöscht werden.',

        toggleState: 'aktivieren / deaktivieren',
        toggleStateSuccess: 'Status wurde erfolgreich geändert.',
        toggleStateError: 'Der Status konnte nicht geändert werden.',

        info: {
            general: 'Allgemeine Informationen',
            currentVersion: 'Aktuelle Version',
            currentFeatures: 'Neueste Funktionen',
            bugfixes: 'Bugfixes',
            isCurrentVersion: 'Aktuellste Version',
            loadNewestVersion: 'Neueste Version laden',
            isOldVersion: 'Veraltete Version',
            releaseNotes: 'Release Notes'
        },

        table: {
            title: 'Organisationen',
            name: 'Name',
            email: 'E-Mail',
            created_at: 'Erstellt am',
            active: 'Status',
            isActive: 'Aktiv',
            isInactive: 'Inaktiv',
            id: 'ID'
        },

        menu: {
            organisationBaseData: 'Daten'
        }
    },

    notifications: {
        title: 'Benachrichtigungen',
        emptyTitle: 'Noch keine Benachrichtigungen',
        emptyText: 'Hier werden deine Benachrichtigungen angezeigt.'
    },

    testUsers: {
        table: {
            title: 'Mein User',

            email: 'E-Mail',
            customName: 'Anzeigename',
            firstname: 'Vorname',
            role: 'Rolle',
            role_name: 'Rolle',
            created_at: 'Erstellt am',
            id: 'ID',
            impersonate: 'Support geben',

            qf_onlyEmployees: 'Nur Mitarbeiter'
        }
    },

    activityLog: {
        title: 'Aktivitäten',
        noActivities: 'Noch keine Aktivitäten',
        noChanges: 'Keine Änderungen',
        changes: 'Änderungen'
    },

    loginProviders: {
        provider: 'Anbieter',
        client_id: 'Client ID',
        client_secret: 'Client Secret',
        redirect: 'URL',
        tenant_id: 'Tenant ID',

        providerId: 'Login Methode {id}',

        createProvider: 'Anbieter hinzufügen',
        deleteProvider: 'Anbieter löschen',
        deleteProviderInfo:
            'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        noOrganisationFound: 'Es konnte keine Organisation gefunden werden.',
        providerAlreadyExists: 'Es existiert bereits eine Login Method zu diesem Anbieter.',
        providerNotDeletable: 'Dieser Anbieter kann nicht gelöscht werden.',
        deleteProviderSuccess: 'Der Anbieter wurde erfolgreich gelöscht.',
        redirect_url: 'Umleitungs-URI',

        providers: {
            google: 'Google',
            azure: 'Microsoft'
        },

        table: {
            id: 'ID',
            provider: 'Anbieter',
            client_id: 'Client ID',
            client_secret: 'Client Secret',
            redirect: 'URL',
            created_at: 'Erstellt am'
        }
    },

    pageTitle: {
        index: "Dashboard",
        users: "Nutzer",
        organisations: "Organisationen",
        "settings-loginProviders": "Login Methoden"
    },

    wiki: {
        noWikiExisting: 'Für diese Anwendung existieren keine Hilfeseiten.',
        invalidPage: 'Die aufgerufene Hilfeseite existiert nicht.',
        invalidPageExplanation: 'Die aufgerufene URL ist keiner Hilfeseite zugeordnet, oder es wurde für diese Seite noch kein Inhalt gepflegt.',
        initWikiExplanation: 'Die Homepage und weitere Seiten müssen erst im angebundenen CMS angelegt werden.',
    }
}
