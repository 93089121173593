<template>
    <div>
        <h1 class="mb-2">
            Hallo
        </h1>
        <p class="mb-4">
            Beantrage jetzt in vier Schritten deine Juleica! Bitte gib zunächst deine <span v-if="loginmode==='email'">E-Mail-Adresse</span><span v-else>Handynummer</span> ein.
        </p>


        <VForm
            v-if="!$get(authStore, 'isAuthenticated', false) && !passwordReset"
            v-slot="validation"
            ref="formValidatorLogin"
            as="div"
            :keep-values="true">
            <BaseInput
                v-if="loginmode==='email'"
                v-model="email"
                :label="$t('application.email')"
                name="email"
                :rules="{required: true, email: true}"
                validation-mode="aggressive"
                @input="userHasAccount = false" />

            <BaseInput
                v-if="loginmode==='mobile'"
                v-model="mobile"
                :label="$t('application.mobile')"
                name="mobile"
                :rules="{required: true,numeric:true}"
                required
                validation-mode="aggressive"
                group
                input-icon-position="left"
                @input="userHasAccount = false">
                <template #inputIcon>
                    +49
                </template>
            </BaseInput>

            <small
                v-if="!userHasAccount"
                class="float-right underline cursor-pointer mb-3">
                <span
                    v-if="loginmode==='email'"
                    @click="loginmode='mobile'">Du hast keine E-Mail-Adresse?</span>
                <span
                    v-else
                    @click="loginmode='email'">Doch lieber E-Mail-Adresse nutzen</span>
            </small>

            <div v-if="userHasAccount">
                <p class="mb-2 mt-4">
                    Du besitzt bereits ein Konto im Juleica-Antragssystem. <br><br> Bitte gib dein Passwort ein - so können wir dir die Beantragung noch mehr erleichtern.
                </p>
                <BaseInput
                    v-model="localPassword"
                    identifier="application.password"
                    name="password"
                    :rules="{required: true}"
                    required
                    type="password"
                    validation-mode="aggressive" />
                <small class="float-right underline cursor-pointer mb-3">
                    <span @click="userHasAccount = false;passwordReset = true">Passwort vergessen?</span>
                </small>
            </div>

            <BaseButton
                :disabled="loading || (validation ? !!Object.keys(validation.errors).length : false)"
                color="primary"
                size="lg"
                class="mt-5 w-full"
                native-type="submit"
                @mouseenter="validation ? validation.validate() : null"
                @click="onSubmitLogin">
                <span v-if="!loading">{{ $t('application.next') }}</span>
                <span v-else><FontAwesomeIcon
                    spin
                    icon="circle-notch" /></span>
            </BaseButton>
        </VForm>

        <VForm
            v-else-if="!$get(authStore, 'isAuthenticated', false) && passwordReset"
            v-slot="validation"
            ref="formValidatorResetPassword"
            as="div"
            :keep-values="true">
            <h5 class="mb-4">
                Passwort zurücksetzen
            </h5>

            <BaseInput
                v-if="loginmode==='email'"
                v-model="email"
                :label="$t('application.email')"
                name="email"
                :rules="{required: true, email: true}"
                required
                validation-mode="aggressive" />

            <BaseInput
                v-if="loginmode==='mobile'"
                v-model="mobile"
                :label="$t('application.email')"
                name="mobile"
                :rules="{required: true,numeric:true}"
                required
                validation-mode="aggressive"
                group>
                <template #inputIcon>
                    +49
                </template>
            </BaseInput>

            <small
                v-if="passwordReset"
                class="float-right underline cursor-pointer mb-3">
                <span @click="userHasAccount = false;passwordReset = false">Zurück zum Login</span>
            </small>

            <BaseButton
                :disabled="loading || (validation ? !!Object.keys(validation.errors).length : false)"
                size="lg"
                color="primary"
                class="mt-5 w-full"
                native-type="submit"
                @mouseenter="validation ? validation.validate() : null"
                @click="onSubmitResetPassword">
                <span v-if="!loading">{{ $t('auth.resetPassword') }}</span>
                <span v-else><FontAwesomeIcon
                    spin
                    class="ml-2"
                    icon="circle-notch" /></span>
            </BaseButton>
        </VForm>

        <div
            v-else
            class="my-5">
            <label class="font-bold">Angemeldet als
                <small class="float-right underline cursor-pointer">
                    <span @click="logout"><span v-if="loading"><FontAwesomeIcon
                        spin
                        class="mr-2"
                        icon="circle-notch" /></span> Abmelden</span>
                </small>
            </label>
            <p>
                {{ $get(authStore, 'userData.firstname', '') }} {{ $get(authStore, 'userData.lastname', '') }}
                <span v-if="$get(authStore, 'userData.loginmode', '')==='mobile'">(+49{{ $get(authStore, 'userData.mobile', '') }})</span>
                <span v-else>({{ $get(authStore, 'userData.email', '') }})</span>
            </p>

            <BaseButton
                size="lg"
                color="primary"
                class="mt-5 w-full"
                @click="$emit('nextPage')">
                Weiter
            </BaseButton>
        </div>
    </div>
</template>

<script>
import applicationFields from "@/mixins/application/applicationFields";
import Swal from "sweetalert2";
import dateConvert from "../../../mixins/dateConvert";
import {useAuthStore} from "@/store-pinia/auth";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "StepEmail",
    components: {FontAwesomeIcon},
    mixins: [applicationFields, dateConvert],
    data() {
        return {
            localPassword: '',
            passwordReset: false,
            loading: false,
            authStore: useAuthStore(),
            notification: useNotification()
        }
    },

    mounted() {
        this.loadUserData(true)
    },

    methods: {

        onSubmitLogin() {
            this.loading = true;

            if (this.localPassword.length && this.userHasAccount) {
                this.loginUser(true)
                return
            }

            //We do not get the xrsf Token here because it is done by a axios Interceptor
            $lara.post(this.$apiRoute('spa.accountExists'), {
                body: {
                    email: this.email,
                    mobile: this.mobile,
                    loginmode: this.loginmode,
                }
            }).then(response => {
                this.userHasAccount = true
                this.loading = false;
            }
            ).catch(error => {
                this.loading = false;
                if (error.response.status === 422) {
                    this.$emit('nextPage')
                } else {
                    this.$store.commit('auth/setShake', true);

                    if (error.response.status === 429) this.notification.error(this.$t('auth.tooManyAttempts'))

                    setTimeout(() => {
                        this.$store.commit('auth/setShake', false);
                    }, 823)
                }
            });
        },

        onSubmitResetPassword() {

            this.loading = true
            $lara.post((this.$apiRoute('spa.frontend.password')), {
                body: {
                    email: this.email,
                    mobile: this.mobile,
                    loginmode: this.loginmode,
                }
            }).then(response => {
                this.loading = false

                if (this.loginmode === 'mobile') {
                    this.notification.success(this.$t('auth.sendSmsPasswordResetLink'))
                } else {
                    this.notification.success(this.$t('auth.sendEmailPasswordResetLink'))
                }
                this.passwordReset = false

            }).catch(error => {
                this.loading = false
                if (error.response.status === 422) {
                    if (error.response._data.errors.email !== undefined && error.response._data.errors.email[0] === "passwords.throttled") {
                        this.notification.error(this.$t('auth.tooManyPasswortResetSend'))
                    } else if (error.response._data.errors.mobile !== undefined && error.response._data.errors.mobile[0] === "passwords.throttled") {
                        this.notification.error(this.$t('auth.tooManyPasswortResetSend'))
                    } else if (error.response._data.errors.email !== undefined && error.response._data.errors.email[0]) {
                        this.notification.error(this.$t('auth.wrongEmailPasswortReset'))
                    } else if (error.response._data.errors.mobile !== undefined && error.response._data.errors.mobile[0]) {
                        this.notification.error(this.$t('auth.wrongMobilePasswortReset'))
                    }
                }
            })
        },


        async loginUser(loadUserData = false) {

            //We do not get the xrsf Token here because it is done by a axios Interceptor
            await $lara.post(this.$apiRoute('spa.frontend.login'), {
                body: {
                    email: this.email,
                    mobile: this.mobile,
                    password: this.localPassword,
                    remember: true
                }
            }).catch(error => {

                this.loading = false;
                this.$store.commit('auth/setShake', true);

                if (error.response.status === 422) this.notification.error(this.$t('auth.wrongCredentials'))
                if (error.response.status === 429) this.notification.error(this.$t('auth.tooManyAttempts'))

                setTimeout(() => {
                    this.$store.commit('auth/setShake', false);
                }, 823)
            });

            this.loading = false
            await useAuthStore().getAuthData()

            if (loadUserData) this.loadUserData()
        },

        async loadUserData(background) {
            if (!background) {
                this.loading = true;
            }

            let attachment = this.$route.query.token ? "?token=" + this.$route.query.token : ''
            let dob = null

            if (this.$route.query.token) {
                let date = null

                const result = await Swal.fire({
                    html: '<div><strong>Bitte gebe dein Geburtsdatum ein um deine Identität zu bestägigen.</strong></div>' +
                        '<input id="dob" type="date" class="form-control mt-4">',
                    confirmButtonText: 'Bestätigen',
                    confirmButtonColor: '#E2007A',
                    showCancelButton: false,
                    preConfirm() {
                        date = document.getElementById('dob').value
                    }
                })

                if (result.value && date) {
                    dob = this.convertToLaravelDate(date)

                    attachment += "&dob=" + dob

                } else {
                    this.notification.error("Das Geburtsdatum war nicht korrekt. Bitte versuche es erneut.")
                    this.loadUserData(background)
                    return
                }

            }

            $lara.get(this.$apiRoute('spa.frontend.user.show') + attachment, {
                redirectIfNotAuthenticated: false
            })
                .then((response) => {
                    if (!response && this.$route.query.token) {
                        //refetch with token
                        console.log('refetch');
                        this.notification.error("Das Geburtsdatum war nicht korrekt. Bitte versuche es erneut.")
                        this.loadUserData(background)
                        return
                    }

                    response.data ? useAuthStore().setUserData(response.data) : null
                    response.data ? useAuthStore().setAuthenticated(true) : null

                    // don't overwrite organisation data...
                    if (this.lockOrganisation) {
                        response.data.lockOrganisation = this.lockOrganisation
                        response.data.organisation = this.organisation
                    }

                    this.setAllApplicationData(response.data)
                    if (this.$route.query.token) {
                        this.setApplicationData('token', this.$route.query.token)
                        this.setApplicationData('dob', dob)
                    }
                    this.userHasAccount = true
                    this.$emit('nextPage')
                    if (!background) this.loading = false;

                }).catch((error) => {
                    if (!background) {
                        this.loading = false;
                    }
                })
        },

        logout() {
            this.loading = true;
            $lara.post(this.$apiRoute('spa.frontend.logout'), {}).then(() => {
                this.loading = false;
                this.setAllApplicationData({})
                useAuthStore().setAuthenticated(false)
                useAuthStore().setUserData(null)
                this.$store.commit('application/setDoneTillStep', 0)
            }).catch(error => {
                this.loading = false;
            });
        },
    },
}
</script>

<style scoped>

</style>
