<template>
    <div class="flex flex-wrap mt-4">
        <slot />
    </div>
</template>

<script>
export default {
    name: "TableFilterQuick",
    props: {
    },
}
</script>