export default {

    savedSuccessfully:"Dein Profil wurde erfolgreich aktualisiert.",
    deletedSuccessfully:"Benutzer*in {firstname} {lastname} wurde erfolgreich gelöscht.|Benutzerin {firstname} {lastname} wurde erfolgreich gelöscht.|Benutzer {firstname} {lastname} wurde erfolgreich gelöscht.",

    imageCrop:"Übernehmen",
    imageCropCancel:"Abbrechen",

    gender:"Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    streetNumber: 'Straße und Hausnummer',
    street: "Straße",
    housenumber: "Hausnummer",
    zipCity: "PLZ und Ort",
    zipcode: "PLZ",
    city: "Ort",
    country:"Land",
    dateofbirth: "Geburtsdatum",
    email: "E-Mail",
    mobile: "Handynummer",
    password: "Passwort",
    image: "Bild",
    passwordRepeat: "Passwort wiederholen",

    resend_cta_email:"Bestätigungsemail",
    resend_cta_mobile:"Bestätigungs-SMS",

    resend_activation_email:"E-Mail wurde erneut verschickt. Bitte prüfe auch deinen SPAM-Ordner.",
    resend_activation_mobile:"SMS wurde erneut verschickt."
}
