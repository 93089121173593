<template>
    <div class="flex flex-col items-center mt-5">
        <FontAwesomeIcon
            icon="circle-notch"
            class="text-primary"
            spin
            size="6x" />
        <h5 class="text-gray-500 mt-5 text-center">
            {{ currentSnipped }}
        </h5>
    </div>
</template>

<script>
export default {
    name: "StepApplicationSubmitLoading",
    data() {
        return {
            textSnippets: [
                "Schneide eine Juleica-Karte aus...",
                "Schreibe deinen Namen auf die Juleica...",
                "Klebe ein hübsches Bild von dir auf deine neue Karte...",
                "Schicke eine Mail an deinen Träger...",
                "Mache alles fertig..."
            ],
            currentSnippedNumber: 0
        }
    },
    computed: {
        currentSnipped() {
            return this.textSnippets[this.currentSnippedNumber]
        }
    },
    activated() {
        this.currentSnippedNumber = 0
        this.incrementSnippedNumber()
    },
    methods: {
        incrementSnippedNumber() {
            if (this.currentSnippedNumber < this.textSnippets.length - 1) {
                setTimeout(() => {
                    this.currentSnippedNumber++;
                    this.incrementSnippedNumber()
                }, 2500)
            }
        }
    }
}
</script>

<style scoped>

</style>