import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import AllRules from '@vee-validate/rules';
import {localize, setLocale} from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import val_de from './vee-validate/val_de.json'
import val_en from './vee-validate/val_en.json'
import zxcvbn from "zxcvbn";

const url_regex = '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$'; // fragment locator

const rulesForErrorMessage = [
    'validation_pending'
]

export default defineNuxtPlugin((nuxtApp) => {

    if (process.client) {
        /**************
         * Custom rules
         **************/

        defineRule('verify_password', value => value && zxcvbn(value).score > 2);
        defineRule('confirm_password', (value, [password]) => value === password);
        defineRule('birthday', (value, [age, minimumAge]) => {
            return age >= minimumAge
        });
        defineRule('valid_date', (value, [age]) => {
            const maximumAge = 150
            return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.exec(value) && age < maximumAge
        });
        defineRule('verify_url', value => new RegExp(url_regex, 'i').test(value));

        rulesForErrorMessage.forEach(rule => defineRule(rule, () => false))

        /**************
         * Configuration
         **************/

        Object.keys(AllRules).forEach(rule => {
            defineRule(rule, AllRules[rule]);
        });

        configure({
            generateMessage: localize({
                en: {...en, messages: {...en.messages, ...val_en}},
                de: {...de, messages: {...de.messages, ...val_de}}
            })
        });

        setLocale('de');

        nuxtApp.vueApp.component("VField", Field)
        nuxtApp.vueApp.component("VForm", Form)
        nuxtApp.vueApp.component("ErrorMessage", ErrorMessage)

        configure({
            classes: {
                valid: 'is-valid',
                invalid: 'is-invalid',
                dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
            }
        });
    }
})