<template>
    <div>
        <JuleicaTable
            class="mb-4">
            <thead>
                <tr>
                    <JuleicaTableHead :colspan="useCharts ? 1 : 2">
                        <!--<BTooltip
                            :target="'info-circle-heading-'+entity+dataType"
                            :triggers="['hover', 'click']">
                            <span v-if="dataType === 'newOrExtension'">{{ $t('statistics.helpText.newOrExtension')
                            }}</span>
                            <span v-else-if="dataType === 'ageGroups'">{{ $t('statistics.helpText.age') }}</span>
                            <span v-else-if="dataType === 'years'">{{ $t('statistics.helpText.years') }}</span>
                        </BTooltip>-->
                        <div class="flex gap-1">
                            {{ tableHeading }}
                            <BasePopover
                                size="sm">
                                <template #trigger>
                                    <FontAwesomeIcon
                                        v-if="dataType === 'newOrExtension' || dataType === 'ageGroups' || dataType === 'years'"
                                        class="mt-0.5"
                                        icon="info-circle" />
                                </template>
                                <template #default>
                                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                                        <span v-if="dataType === 'newOrExtension'">{{ $t('statistics.helpText.newOrExtension')
                                        }}</span>
                                        <span v-else-if="dataType === 'ageGroups'">{{ $t('statistics.helpText.age') }}</span>
                                        <span v-else-if="dataType === 'years'">{{ $t('statistics.helpText.years') }}</span>
                                    </span>
                                </template>
                            </BasePopover>
                            <!--<BTooltip
                                :target="'question-circle-age-' + index"
                                triggers="hover">
                                {{ $t('statistics.helpText.ageGroupNotEnoughData') }}
                            </BTooltip>-->
                        </div>
                    </JuleicaTableHead>
                    <JuleicaTableHead v-if="useCharts">
                        <FontAwesomeIcon
                            icon="table"
                            :class="{'text-primary': activeChart === 'table'}"
                            class="cursor-pointer"
                            @click="activeChart = 'table'" />
                        <span v-if="usePieChart">
                            | <FontAwesomeIcon
                                icon="chart-pie"
                                :class="{'text-primary': activeChart === 'pie'}"
                                class="cursor-pointer"
                                @click="activeChart = 'pie'" />
                        </span>
                    </JuleicaTableHead>
                </tr><tr />
            </thead>
            <JuleicaTableBody v-if="activeChart === 'table'">
                <JuleicaTableBodyRow v-if="typeof rows === 'string' || rows instanceof String">
                    <JuleicaTableColumn colspan="2">
                        <div class="flex gap-1">
                            {{ rows }}
                            <BasePopover
                                size="sm">
                                <template #trigger>
                                    <FontAwesomeIcon
                                        class="mt-0.5"
                                        icon="question-circle" />
                                </template>
                                <template #default>
                                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                                        {{ $t('statistics.helpText.ageGroupNotEnoughData') }}
                                    </span>
                                </template>
                            </BasePopover>
                        </div>
                    </JuleicaTableColumn>
                </JuleicaTableBodyRow>
                <JuleicaTableBodyRow
                    v-for="(row, index) in rows"
                    v-else
                    :key="index">
                    <JuleicaTableColumn class="w-1/2">
                        {{ dataType === 'ageGroups' || dataType === 'years' ? index : $t('statistics.table.' + index) }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn>
                        {{
                            dataType === 'years' ? row.count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : row.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                        }}
                        <small
                            v-if="$get(rowsPercent, index, null) !== null"
                            :id="row+index+'CountPercent'">
                            {{ rowsPercent[index] }}%
                        </small>
                    </JuleicaTableColumn>
                    <!--<BTooltip
                        :target="row+index+'CountPercent'"
                        placement="right">
                        Prozentualer Anteil an abgeschlossenen Anträgen
                    </BTooltip>-->
                </JuleicaTableBodyRow>
            </JuleicaTableBody>
        </JuleicaTable>
        <JuleicaBaseCard
            v-if="activeChart === 'pie'"
            :rounded="false"
            min-padding>
            <PieChart
                :data="chartData"
                :options="{responsive: false,maintainAspectRatio: false}" />
        </JuleicaBaseCard>
    </div>
</template>

<script>
import {Pie} from 'vue-chartjs'
export default {
    name: "StatisticCardTable",
    components: {
        PieChart: Pie
    },
    props: {
        rows: {
            type: Object,
            required: true
        },
        rowsPercent: {
            type: Object,
            default: null
        },
        dataType: {
            type: String,
            required: true
        },
        charts: {
            type: Array,
            default: [],
            description: 'List of charts that should get shown. Available charts: pie'
        },
        entity: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            activeChart: 'table'
        }
    },
    computed: {
        tableHeading() {
            switch (this.dataType) {
            case 'years':
                return 'Jahre'
            case 'ageGroups':
                return 'Alter'
            case 'newOrExtension':
                return 'Neuantrag oder Verlängerungsantrag'
            case 'startedBy':
                return 'Antrag gestartet von'
            }
        },
        useCharts() {
            return this.charts.length > 0 && !(typeof this.rows === 'string' || this.rows instanceof String)
        },
        usePieChart() {
            return this.charts.includes('pie')
        },
        chartData() {
            const labels = (this.rows !== null) ? Object.keys(this.rows) : []
            const datasets = (this.rows !== null) ? Object.values(this.rows) : []
            return {
                labels: labels,
                datasets: [
                    {
                        backgroundColor: ['#FED42F', '#f39c12', '#d35400', '#e74c3c', '#c0392b', '#9f3126'],
                        data: datasets
                    }
                ]
            }
        }
    }
}
</script>

<style>
.chart-card {
    border: none;
    border-radius: 0;
    box-shadow: 8px 0 8px -8px rgba(0, 0, 0, 0.15), -8px 0 8px -8px rgba(0, 0, 0, 0.15)
}
</style>
