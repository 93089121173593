import {$fetch, FetchContext, FetchError, FetchOptions, FetchResponse} from "ofetch";
import {useAuthStore} from "../store-pinia/auth";
import {useNotification} from "../composables/useNotification";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";

// Unfortunately could not import these types from ohmyfetch, so copied them here
interface ResponseMap {
    blob: Blob;
    text: string;
    arrayBuffer: ArrayBuffer;
}

type ResponseType = keyof ResponseMap | "json";
// end of copied types

type LarafetchOptions<R extends ResponseType> = FetchOptions<R> & {
    redirectIfNotAuthenticated?: boolean;
    redirectIfNotVerified?: boolean;
    formDataRequest?: boolean;
};

type LaraFetchMethod = <T, R extends ResponseType = "json">(path: RequestInfo, options?: LarafetchOptions<R>) => Promise<T>;

type LaraFetchMethods = {
    get: LaraFetchMethod;
    post: LaraFetchMethod;
    put: LaraFetchMethod;
    patch: LaraFetchMethod;
    delete: LaraFetchMethod;
};

export const $larafetch = _larafetch
export const $lara = createHelperMethods();

async function _larafetch<T, R extends ResponseType = "json">(
    path: RequestInfo,
    {
        redirectIfNotAuthenticated = true,
        formDataRequest = false,
        ...options
    }: LarafetchOptions<R> = {}
) {
    const {backendUrl, frontendUrl} = useRuntimeConfig().public;
    const router = useRouter();
    const {$i18n} = useNuxtApp()

    let token = useCookie(CSRF_COOKIE).value;

    // on client initiate a csrf request and get it from the cookie set by laravel
    if (
        !token && process.client &&
        ["post", "delete", "put", "patch"].includes(
            options?.method?.toLowerCase() ?? ""
        )
    ) {
        await initCsrf();
        // cannot use nuxt composables such as useCookie after an async operation: https://github.com/nuxt/framework/issues/5238
        token = getCookie(CSRF_COOKIE);
    }

    let headers: any = {
        ...options?.headers,
        ...(token && {[CSRF_HEADER]: token}),
        accept: "application/json",
    };

    // let header be set automatically for form data requests
    if (!formDataRequest) headers["content-type"] = "application/json"

    if (process.server) {
        headers = {
            ...headers,
            ...useRequestHeaders(["cookie"]),
            referer: frontendUrl,
        };
    }

    try {
        if (options.params) {
            Object.keys(options.params).forEach((key) => {
                if (options.params && Array.isArray(options.params[key])) {
                    options.params[key] = JSON.stringify(options.params[key]);
                }
            });
        }

        return await $fetch<T, R>(path, {
            baseURL: backendUrl,
            ...options,
            headers,
            credentials: "include",
            onResponse: (context: FetchContext & {
                response: FetchResponse<R>
            }): Promise<void> | void => handleResponse(context.response, options.method)
        });
    } catch (error) {
        if (!(error instanceof FetchError)) throw error;

        // when any of the following redirects occur and the final throw is not caught then nuxt SSR will log the following error:
        // [unhandledRejection] Error [ERR_HTTP_HEADERS_SENT]: Cannot set headers after they are sent to the client

        const status = error.response?.status ?? -1;

        if ([500].includes(status)) {
            useNotification().error($i18n.t("serverError"), $i18n.t("serverErrorSupport"));
        }

        if (redirectIfNotAuthenticated && [401, 419].includes(status) && !router.currentRoute?.value?.name?.startsWith("auth")) {
            useNotification().error($i18n.t("auth.sessionTimeout"), $i18n.t("auth.pleaseLoginAgain"));
            useAuthStore().resetAuthData()
            await router.push("/auth");
        }

        throw error;
    }
}

function handleResponse(response: any, method: string | undefined = 'get') {
    const authStore = useAuthStore()
    const farbcode_version = response.headers.get('farbcode-version')
    if (!!farbcode_version && farbcode_version !== authStore.remoteVersion && method === 'get')
        authStore.setRemoteVersion(farbcode_version)
    return response
}

function createHelperMethods(): LaraFetchMethods {
    const helperMethods: any = {}

    for (const method of ["get", "post", "put", "patch", "delete"]) {
        helperMethods[method] = async <T, R extends ResponseType = "json">(path: RequestInfo, options?: LarafetchOptions<R>) => {
            return await _larafetch<T, R>(path, {
                ...options,
                method
            })
        }
    }
    return helperMethods
}

async function initCsrf() {
    const {backendUrl} = useRuntimeConfig().public;

    await $fetch("/app/sanctum/csrf-cookie", {
        baseURL: backendUrl,
        credentials: "include",
    });
}

// https://github.com/axios/axios/blob/bdf493cf8b84eb3e3440e72d5725ba0f138e0451/lib/helpers/cookies.js
function getCookie(name: string) {
    const match = document.cookie.match(
        new RegExp("(^|;\\s*)(" + name + ")=([^;]*)")
    );
    return match ? decodeURIComponent(match[3]) : null;
}
