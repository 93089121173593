export default {
    valid: 'Gültige Karten am Ende des Zeitraums',
    lineChartToLongTimeInterval: 'Der ausgewählte Zeitraum ist zu lang für einen zeitlichen Verlauf.',
    ageGroupNotEnoughData: 'Um die Anonymität zu gewährleisten, können Zahlen nur angezeigt werden, wenn eine Mindestzahl von Anträgen in der entsprechenden Kategorie vorliegt.',
    agsTable: 'Die geografische Lage richtet sich nach dem im System hinterlegten Sitz des Trägers.',
    newOrExtension: 'Da technisch gesehen zunächst kein Unterschied zwischen Neuantrag und Verlängerungsantrag besteht, kann das System nicht immer korrekt zuordnen, ob ein Verlängerungsantrag vorliegt. Zu vermuten ist, dass die angegebenen Zahlen den Anteil der Verlängerungsanträge unterschätzen.',
    ready: 'Ein Antrag wird als abgeschlossen gewertet, sobald die Druckerei im Antragssystem zurückmeldet, dass Druck und Versand der betreffenden Karten erfolgt sind. Entsprechend kann die hier dargestellte Statistik von anderen abweichen, die z.B. das Datum des Drucks oder der Antragstellung zugrundelegen.',
    oldData: 'Die hier vorliegenden Daten sind erst ab 2019 weitgehend vollständig.',
    age: 'Das hier angezeigte Alter ist das Alter bei Abschluss des Antrags.',
    automaticExtension: '2020 und z.T. auch 2021 wurden Karten aufgrund der Pandemiesituation automatisch verlängert. Das beeinflusst massiv die Statistik bei den gültigen Karten für diesen Zeitraum.',
    growthFactor: 'Verhältnis zwischen neuen Karten und abgelaufenen Karten im ausgewählten Zeitraum. Wenn die Zahl größer als 1 ist, steigt die Anzahl der gültigen Karten. Bei einer Zahl unter 1 reduziert sich die Anzahl der gültigen Karten.',
    years: "Zahlen gruppiert nach Jahren. Jahre die in dem ausgewählten Zeitraum abgeschnitten werden, können hier nicht angezeigt werden.",
}
