<template>
    <div class="p-4 border-[5px] border-secondary rounded-lg text-black mt-24">
        <div class="grid grid-cols-6 gap-3">
            <div class="col-span-1">
                <img
                    class="h-16"
                    src="~assets/sachsen_emblem.png">
            </div>
            <div class="col-span-5">
                <h2 class="text-left">
                    Sächsische <br> Ehrenamtskarte
                </h2>
            </div>
        </div>
        <div v-if="!submitted">
            <div class="text-left mt-5">
                Du kannst mit diesem Antrag auch die Sächsische Ehrenamtskarte bestellen. Sie wird an die angegebene Adresse versendet.<br>
                Mit deiner persönlichen Ehrenamtskarte bekommst du verschiedene Vergünstigungen, z.B. in Museen, Freizeitstätten oder Jugendherbergen.
            </div>

            <div class="text-left mt-5">
                <a
                    class="underline"
                    target="_blank"
                    href="https://www.ehrenamt-sachsen.de/saechsische-ehrenamtskarte.html">Infos zur Sächsischen Ehrenamtskarte</a>
                <VForm
                    v-slot="validation"
                    ref="formValidatorSachsen"
                    as="div"
                    :keep-values="true">
                    <!--todo <ValidationProvider
                            v-slot="{errors, valid, invalid, validated}"
                            :rules="{ required: { allowFalse: false } }"
                            name="Datenschutz">-->
                    <BaseCheckbox
                        v-model="privacy"
                        :rules="{required: true}"
                        name="Datenschutz"
                        required
                        class="mt-3">
                        Ich habe die <a
                            class="underline"
                            href="/datenschutz"
                            target="_blank">Datenschutzerklärung</a>
                        gelesen und erkläre mich damit einverstanden.*
                        <br><small>Bei Beantragung der Ehrenamtskarte des Freistaates Sachsen gilt: Die
                            Ehrenamtsagentur Sachsen, Königsbrücker Straße 28-30, 01099 Dresden, erhält die Daten
                            “Vor- und Nachname, Postanschrift (Straße, Hausnummer, Postleitzahl, Ort), Geburtsjahr,
                            Träger mit Postanschrift (Straße, Hausnummer, Postleitzahl, Ort)” der
                            Juleica-Antragsteller, die auch eine Ehrenamtskarte des Freistaates Sachsen erhalten
                            wollen und entsprechend bei der Juleica-Antragstellung votieren, von der Firma farbcode.
                            Sie nutzt diese Daten ausschließlich zum Zweck des Drucks und Versands der
                            Ehrenamtskarte des Freistaates Sachsen. Die Daten werden bei der Ehrenamtsagentur
                            Sachsen nach drei Jahren gelöscht.</small>
                    </BaseCheckbox>

                    <!--<div
                                v-if="errors[0]"
                                class="invalid-feedback ml-4 mb-2"
                                style="display: block;">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>-->

                    <BaseButton
                        color="secondary"
                        class="mt-3 w-full mb-2"
                        size="lg"
                        :disabled="submitLoading || (validation ? !!Object.keys(validation.errors).length : false)"
                        @mouseenter="validation ? validation.validate() : null"
                        @click="onSubmit()">
                        <span v-if="!submitLoading">Sächsische Ehrenamtskarte jetzt kostenfrei und ohne Verbindlichkeiten bestellen</span>
                        <span v-else><FontAwesomeIcon
                            spin
                            icon="circle-notch" /></span>
                    </BaseButton>
                </VForm>
            </div>
        </div>
        <div
            v-else
            class="mt-4 mb-2">
            <FontAwesomeIcon
                icon="check"
                class="text-success" />
            Sächsische Ehrentamtskarte erfolgreich beantragt
        </div>
    </div>
</template>

<script>
import applicationMain from '~/mixins/application/applicationMain'

export default {
    name: "FsExtensionSachsenVolunteerCards",
    components: {},
    mixins: [applicationMain],
    props: {
        mainNamespace: {
            type: String
        },
        submitted: {
            type: Boolean
        },
        name: {
            type: String
        }
    },
    data() {
        return {
            loading: false,
            submitLoading: false,
            privacy: false,
            namespace: 'sachsenVolunteerCard',
            notification: useNotification()
        }
    },
    computed: {},
    methods: {
        async onSubmit() {

            this.submitLoading = true

            $lara.post(this.$apiRoute('spa.frontend.fsExtensionSachsenVolunteerCard.store'), {
                body: {'card_id': this.submitResponse.card_id, 'token': this.submitResponse.token}
            }).then(response => {

                this.submitLoading = false;

                this.emitOnSubmit()

                this.notification.success(this.$t(this.mainNamespace + "." + this.namespace + ".savedSuccessfully"))

            }).catch(error => {
                if (error.response && error.response.data.errors !== undefined) this.$refs.formValidator.setErrors(error.response.data.errors);
                this.submitLoading = false
                this.notification.error(error.response !== undefined ? this.$t('axios.' + error.response.data.message.replace(".", "")) : this.$t('axios.errorWhileFetchingData'))
            });
        },

        emitOnSubmit(event) {
            this.$emit('onSubmit')
        }
    }
}
</script>

<style  scoped>
    .sachsen-div {
        padding: 1rem;
        border: 5px solid #FED42F;
        border-radius: 1rem;
        color: black;
    }

    img {
        max-width: 100%
    }

    .text-align-left {
        text-align: left;
    }

    .submit-button {
        background-color: $yellow;
    }
</style>
