<template>
    <div>
        <h1>Zusammenfassung</h1>
        <div class="mt-5">
            <div class="flex justify-between mb-1">
                <h6>Bitte kontrolliere deine Daten:</h6>
                <a
                    class="text-primary border-0 cursor-pointer"
                    tabindex="0"
                    @click="currentStep = 1"
                    @keydown.enter="currentStep = 1">Bearbeiten</a>
            </div>
        </div>
        <div
            ref="juleicaContainer"
            class="juleica-container">
            <img
                class="juleica-image"
                src="~assets/card_clean.svg"
                alt="Juleica">
            <img
                class="user-image"
                :src="imageUrl"
                alt="Bild der/des Karteninhabers*in">
            <div
                class="card-data"
                :style="{'font-size': juleicaFontSize * 100 + '%'}">
                <div>{{ firstname }} {{ lastname }}</div>
                <div v-if="date_of_birth">
                    {{ $d(parseAndGetDate(date_of_birth), 'dateNumeric') }}
                </div>
                <div v-if="organisation">
                    {{ organisation.name_short }}
                </div>
                <div v-if="organisation">
                    {{ poOrganisationName }}
                </div>
                <div v-if="organisation">
                    {{ $get(federalStates,federal_state_id+".name",'') }}
                </div>
                <div>XXXXXXXXX gültig bis XX.XX.XXXX</div>
            </div>
            <div
                v-if="$get(federalStates, federal_state_id+'.id', 0) === 14"
                class="card-type-sachsen"
                :style="{'font-size': juleicaFontSize * 100 + '%'}">
                <div>{{ educationDescription7 }}</div>
            </div>
        </div>
        <div
            v-if="organisation"
            class="mt-5 address">
            <div class="mt-3">
                <div class="flex justify-between mb-1">
                    <h6>Versand an:</h6>
                    <a
                        v-if="!organisation.use_organisation_shipping_address"
                        class="text-primary border-0 cursor-pointer"
                        tabindex="0"
                        @click="currentStep = 1"
                        @keydown.enter="currentStep = 1">Bearbeiten</a>
                </div>
                <div v-if="!organisation.use_organisation_shipping_address">
                    <div>{{ firstname }} {{ lastname }}</div>
                    <div>{{ street }} {{ housenumber }}</div>
                    <div>{{ zipcode }} {{ city }}</div>
                </div>
                <div v-else>
                    <div>{{ organisation.name }}</div>
                    <div>{{ organisation.street }} {{ organisation.housenumber }}</div>
                    <div>{{ organisation.zipcode }} {{ organisation.city }}</div>
                </div>
            </div>
        </div>
        <div class="mt-5">
            <div class="flex justify-between mb-1">
                <h6>Dein Juleica Zugang:</h6>
                <a
                    v-if="!userHasAccount"
                    class="text-primary border-0 cursor-pointer"
                    tabindex="0"
                    @click="currentStep = 1"
                    @keydown.enter="currentStep = 1">Bearbeiten</a>
            </div>
            <div v-if="loginmode==='mobile'">
                Handynummer: +49{{ mobile }}
            </div>
            <div v-else>
                E-Mail: {{ email }}
            </div>
        </div>
        <div class="mt-3">
            <VForm
                v-slot="validation"
                ref="formValidator"
                as="div"
                :keep-values="true">
                <BaseCheckbox
                    v-model="privacy"
                    name="Datenschutz"
                    :rules="{required: true}"
                    required>
                    Ich habe die <a
                        class="underline"
                        href="/datenschutz"
                        target="_blank">Datenschutzerklärung</a> gelesen und erkläre mich damit einverstanden.*
                </BaseCheckbox>

                <BaseCheckbox
                    v-model="selfCommitment"
                    name="Selbstverpflichtungserklärung"
                    :rules="{required: true}"
                    required>
                    Ich habe die <span
                        class="underline"
                        @click="showRequirements=!showRequirements"><span>Selbstverpflichtungserklärung</span></span> gelesen und erkläre mich damit einverstanden.*
                </BaseCheckbox>

                <div
                    v-if="showRequirements"
                    class="my-4 ml-4">
                    <h4>Selbstverpflichtungserklärung</h4>

                    <p>Hiermit versicherst du, dass du alle Voraussetzungen zum Erwerb der Jugendleiter/innen-Card (Juleica) erfüllst. Dazu gehören insbesondere:</p>

                    <ul>
                        <li>eine ehrenamtliche Tätigkeit in der Jugendarbeit i. S. des §73 Kinder- und Jugendhilfegesetzes, bzw. eine Tätigkeit, die in dem Bundesland Deines Trägers für die Ausstellung einer Juleica gelten</li>
                        <li>eine Ausbildung, welche den gültigen Anforderungen enspricht, die für das jeweilige Bundesland und ggf. für die Organisation, für die du tätig bist, gültig sind.</li>
                    </ul>

                    <p>Du versicherst ebenfalls, dass alle von dir gemachten Angaben richtig sind und das dir bekannt ist, dass die Juleica auf Verlangen zurückzugeben ist, wenn die o.g. Voraussetzunge nicht mehr gegeben sind oder deine persönliche Eignung nicht mehr gegeben ist.</p>
                </div>

                <BaseButton
                    :disabled="(validation ? !!Object.keys(validation.errors).length : false)"
                    color="primary"
                    class="mt-5 w-full"
                    size="lg"
                    @mouseenter="validation ? validation.validate() : null"
                    @click="onSubmit">
                    <span>Juleica jetzt beantragen!</span>
                </BaseButton>
            </VForm>
        </div>
    </div>
</template>

<script>
import applicationFields from "@/mixins/application/applicationFields";
import federalStates from "@/mixins/federalStates";
import dateConvert from "@/mixins/dateConvert";

export default {
    name: "StepCheck",
    filters: {
        leadingZero: function (value) {
            const zero = 2 - value.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + value;
        }
    },
    mixins: [applicationFields,federalStates,dateConvert],
    data() {
        return {
            juleicaFontSize: 1,
            showRequirements:false
        }
    },
    computed: {
        poOrganisationName: function () {
            return this.$get(this.organisation, "ao_organisation", null) ? this.$get(this.organisation,"ao_organisation.name_short","") : this.$get(this.organisation,"po_organisation.name_short","")
        }
    },
    mounted() {
        this.getWindowWidth();
        window.addEventListener("resize", this.getWindowWidth);
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.getWindowWidth);
    },
    methods: {
        onSubmit() {

            // check birthdate
            this.date_of_birth = this.convertToLaravelDate(this.date_of_birth);

            this.submitJuleicaApplication();
        },
        getWindowWidth() {
            if (this.$refs.juleicaContainer)
                this.juleicaFontSize = this.$refs.juleicaContainer.clientWidth / 345;
        }
    },
}
</script>

<style  scoped>
    .juleica-container {
        position: relative;

        .juleica-image {
            width: 100%;
        }

        .user-image {
            width: 19%;
            position: absolute;
            right: 6%;
            top: 6%;
        }

        .card-data {
            position: absolute;
            display: flex;
            flex-direction: column;
            bottom: 7%;
            left: 10%;

            * {
                line-height: 120%;
                font-size: 90%;
            }
        }

        .card-type-sachsen {
            position: absolute;
            display: flex;
            flex-direction: column;
            bottom: 7%;
            right: 6%;

            * {
                line-height: 120%;
                font-size: 90%;
            }
        }
    }


</style>
