<template>
    <div>
        <h1 class="mb-2">
            Trägerauswahl und Ausbildung
        </h1>
        <p v-if="!lockOrganisation">
            Bitte wähle den Träger aus, bei dem du ehrenamtlich tätig bist.<br><small>Dies gilt
                auch, wenn du deine Ausbildung bei einem anderen Träger gemacht hast.</small>
        </p>
        <p
            v-else
            class="mt-8">
            Dein Träger ist:
        </p>

        <VForm
            v-slot="validation"
            ref="formValidator"
            as="div"
            :keep-values="true">
            <div v-if="!lockOrganisation">
                <div class="flex justify-around mb-5 mt-5">
                    <div
                        class="flex justify-center items-center cursor-pointer h-12 w-32 text-gray-400 border-gray-300 border-solid border-2"
                        :class="{'text-primary border-primary': !useMapMode}"
                        tabindex="0"
                        @click="useMapMode = false"
                        @keydown.enter="useMapMode = false">
                        <span>
                            <FontAwesomeIcon icon="search" />
                            <span> Suchen</span>
                        </span>
                    </div>
                    <div
                        class="flex justify-center items-center cursor-pointer h-12 w-32 text-gray-400 border-gray-300 border-solid border-2"
                        :class="{'text-primary border-primary': useMapMode}"
                        tabindex="0"
                        @click="useMapMode = true"
                        @keydown.enter="useMapMode = true">
                        <span>
                            <FontAwesomeIcon icon="map-marker-alt" />
                            <span> Karte</span>
                        </span>
                    </div>
                </div>
                <div v-if="!useMapMode">
                    <BaseInput label="Suche nach Name, Ort, PLZ, TrägerId">
                        <VSelect
                            v-model="organisation"
                            :options="selectOptions"
                            label="name"
                            placeholder="Kein Träger ausgewählt"
                            :filterable="false"
                            @search="onSearchOrganisation">
                            <template #no-options="{search, loading}">
                                <div
                                    v-if="search === ''"
                                    class="mx-3">
                                    Suche nach Träger (Name, Ort, PLZ, TrägerId)
                                </div>
                                <div
                                    v-else-if="search.length <= 2"
                                    class="mx-3">
                                    Bitte gebe mindestens drei Zeichen ein.
                                </div>
                                <div
                                    v-else-if="!loading"
                                    class="mx-3">
                                    Leider konnten wir zu deiner Suche keine passenden Träger finden.
                                </div>
                            </template>
                            <template #option="option">
                                <div class="d-center">
                                    {{ option.name }}
                                    <br>
                                    <small class="text-muted">{{ option.city }}</small>
                                </div>
                            </template>
                            <template #selected-option="option">
                                <div class="selected d-center">
                                    {{ option.name }}
                                    <br>
                                    <small class="text-muted">{{ option.city }}</small>
                                </div>
                            </template>
                            <template
                                v-if="showMoreHint"
                                #list-footer>
                                <div class="list-footer">
                                    Bitte gib mehr als {{ search.length }} Buchstaben ein, um noch mehr Suchergebnisse
                                    zu erhalten.
                                </div>
                            </template>
                        </VSelect>
                    </BaseInput>
                </div>
                <div v-else>
                    <div
                        id="map-wrapper"
                        class="mb-3">
                        <JuleicaOrganisationsMap v-model:organisation="organisation" />
                    </div>
                </div>
            </div>
            <div
                v-else
                class="my-2">
                <strong>{{ organisation.name }}</strong>
                <br>
                <small class="text-muted">{{ organisation.city }}</small>
            </div>

            <p
                v-if="!showUnknownOrganisation && !lockOrganisation && !applicationDataId"
                class="mb-3">
                <small
                    class="underline cursor-pointer float-right"
                    @click="showUnknownOrganisation = true; organisation = null">
                    <span>Du kannst deinen Träger nicht finden? Dann klicke bitte hier</span></small>
            </p>
            <p
                v-if="showUnknownOrganisation"
                class="mt-4">
                Falls du deinen Verein/Jugendverband nicht finden kannst, versuche
                deinen zuständigen Stadt- oder Kreisverband zu suchen.
            </p>
            <p
                v-if="showUnknownOrganisation"
                class="mt-4">
                Bitte gebe die E-Mail-Adresse deiner Kontaktperson ein. Diese Person
                kann deinen Juleica-Antrag dann für dich weiterleiten.
            </p>

            <BaseInput
                v-if="showUnknownOrganisation"
                label="Bundesland"
                class="mb-3 mt-3">
                <BaseSelect
                    v-model="federal_state_id">
                    <option value="1">
                        Schleswig-Holstein
                    </option>
                    <option value="2">
                        Freie und Hansestadt Hamburg
                    </option>
                    <option value="3">
                        Niedersachsen
                    </option>
                    <option value="4">
                        Freie Hansestadt Bremen
                    </option>
                    <option value="5">
                        Nordrhein-Westfalen
                    </option>
                    <option value="6">
                        Hessen
                    </option>
                    <option value="7">
                        Rheinland-Pfalz
                    </option>
                    <option value="8">
                        Baden-Württemberg
                    </option>
                    <option value="9">
                        Freistaat Bayern
                    </option>
                    <option value="10">
                        Saarland
                    </option>
                    <option value="11">
                        Berlin
                    </option>
                    <option value="12">
                        Brandenburg
                    </option>
                    <option value="13">
                        Mecklenburg-Vorpommern
                    </option>
                    <option value="14">
                        Freistaat Sachsen
                    </option>
                    <option value="15">
                        Sachsen-Anhalt
                    </option>
                    <option value="16">
                        Freistaat Thüringen
                    </option>
                </BaseSelect>
            </BaseInput>

            <BaseInput
                v-if="showUnknownOrganisation"
                v-model="unknownOrganisationMail"
                :label="$t('application.unknownOrganisationMail')"
                name="unknownOrganisationMail"
                :rules="{required: true, email: true}"
                required
                validation-mode="aggressive" />
            <small
                v-if="showUnknownOrganisation"
                class="underline cursor-pointer float-right"
                @click="showUnknownOrganisation = false">Träger doch gefunden?</small>

            <div
                v-if="organisation"
                class="mt-6 mb-10">
                <h6>Träger kontaktieren</h6>
                <small
                    class="underline cursor-pointer"
                    @click="showContactOrganisationModal = true">
                    <span><FontAwesomeIcon icon="question-circle" /> Falls du Hilfe beim Ausfüllen der untenstehenden Felder brauchst oder Fragen zum Antrag hast, kannst du dich hier an deinen ausgewählten Träger wenden.</span>
                </small>
            </div>

            <div
                v-if="organisation || unknownOrganisationMail"
                class="grid grid-cols-1 gap-6 mt-6">
                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[0].label','') !== '' && $get(federalStates[federalStateId],'config[0].label',null) !== null"
                    v-model="educationDescription"
                    :name="federalStates[federalStateId].config[0].field"
                    :label="federalStates[federalStateId].config[0].label"
                    :rules="{required: federalStates[federalStateId].config[0].required}"
                    :required="federalStates[federalStateId].config[0].required"
                    validation-mode="aggressive" />

                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[1].label','') !== '' && $get(federalStates[federalStateId],'config[1].label',null) !== null"
                    v-model="educationDescription2"
                    :name="federalStates[federalStateId].config[1].field"
                    :label="federalStates[federalStateId].config[1].label"
                    :rules="{required: federalStates[federalStateId].config[1].required}"
                    :required="federalStates[federalStateId].config[1].required"
                    validation-mode="aggressive" />

                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[2].label','') !== '' && $get(federalStates[federalStateId],'config[2].label',null) !== null"
                    v-model="educationDescription3"
                    :name="federalStates[federalStateId].config[2].field"
                    :label="federalStates[federalStateId].config[2].label"
                    :rules="{required: federalStates[federalStateId].config[2].required}"
                    :required="federalStates[federalStateId].config[2].required"
                    validation-mode="aggressive" />

                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[3].label','') !== '' && $get(federalStates[federalStateId],'config[3].label',null) !== null"
                    v-model="educationDescription4"
                    :name="federalStates[federalStateId].config[3].field"
                    :label="federalStates[federalStateId].config[3].label"
                    :rules="{required: federalStates[federalStateId].config[3].required}"
                    :required="federalStates[federalStateId].config[3].required"
                    validation-mode="aggressive" />

                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[4].label','') !== '' && $get(federalStates[federalStateId],'config[4].label',null) !== null"
                    v-model="educationDescription5"
                    :name="federalStates[federalStateId].config[4].field"
                    :label="federalStates[federalStateId].config[4].label"
                    :rules="{required: federalStates[federalStateId].config[4].required}"
                    :required="federalStates[federalStateId].config[4].required"
                    validation-mode="aggressive" />

                <BaseTextArea
                    v-if="$get(federalStates[federalStateId],'config[5].label','') !== '' && $get(federalStates[federalStateId],'config[5].label',null) !== null"
                    v-model="educationDescription6"
                    :name="federalStates[federalStateId].config[5].field"
                    :label="federalStates[federalStateId].config[5].label"
                    :rules="{required: federalStates[federalStateId].config[5].required}"
                    :required="federalStates[federalStateId].config[5].required"
                    validation-mode="aggressive" />

                <div
                    v-if="$get(federalStates[federalStateId],'config[6].label','') !== '' && $get(federalStates[federalStateId],'config[6].label',null) !== null">
                    <BaseTextArea
                        v-if="$get(federalStates[federalStateId],'config[6].options',null) === null"
                        v-model="educationDescription7"
                        :name="federalStates[federalStateId].config[6].field"
                        :label="federalStates[federalStateId].config[6].label"
                        :rules="{required: federalStates[federalStateId].config[6].required}"
                        :required="federalStates[federalStateId].config[6].required"
                        validation-mode="aggressive" />
                    <BaseInput
                        v-else
                        v-model="educationDescription7"
                        :name="federalStates[federalStateId].config[6].field"
                        :label="federalStates[federalStateId].config[6].label"
                        :rules="{required: federalStates[federalStateId].config[6].required}"
                        :required="federalStates[federalStateId].config[6].required"
                        validation-mode="aggressive">
                        <BaseSelect
                            v-model="educationDescription7">
                            <option
                                v-for="(option, key) in federalStates[federalStateId].config[6].options"
                                :key="key"
                                :value="option.value">
                                {{ option.label }}
                            </option>
                        </BaseSelect>
                    </BaseInput>
                </div>


                <BaseInput
                    class="mt-5"
                    label="Bitte lade Dokumente (Bilder oder PDF) hoch, die deine Ausbildung
                                belegen (bzw. im Fall eines Verlängerungsantrags deine Fortbildung) oder
                                fotografiere sie ab.">
                    <BaseDropzone
                        v-model="uploadedDocuments"
                        class="mt-1"
                        :max-files="10"
                        multiple
                        accept="image/png, image/*, application/pdf"
                        @success="addUploadedFile"
                        @removedfile="removeFile">
                        <!-- 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',!-->
                    </BaseDropzone>
                </BaseInput>

                <BaseModal
                    v-if="organisation"
                    v-model="showContactOrganisationModal"
                    title="Träger kontaktieren"
                    size="lg">
                    <div>
                        <div class="mb-5 mt-3">
                            <strong>Ausgewählter Träger</strong><br>
                            {{ organisation.name }}<br><br>
                            <strong>Kontaktdaten</strong><br>
                            E-Mail: <a
                                class="underline"
                                :href="'mailTo:'+organisation.email">{{ organisation.email }}</a><br>
                            Telefon: <a
                                class="underline"
                                :href="'callTo:'+organisation.phone">{{ organisation.phone }}</a>
                        </div>
                        <VForm
                            v-slot="validation"
                            ref="contactOrganisationFormValidator"
                            as="div"
                            :keep-values="true">
                            <div class="mb-3">
                                <strong>Kontaktformular</strong>
                            </div>
                            <BaseInput
                                v-model="contactSubject"
                                label="Betreff"
                                name="Betreff"
                                required
                                :rules="{required: true}" />
                            <BaseTextArea
                                v-model="contactMessage"
                                label="Nachricht"
                                name="Nachricht"
                                required
                                :rules="{required: true}" />

                            <div class="flex justify-end mt-5">
                                <BaseButton
                                    :disabled="contactSubmitLoading"
                                    type="gray"
                                    @click="showContactOrganisationModal = false">
                                    Abbrechen
                                </BaseButton>
                                <BaseButton
                                    class="ml-3"
                                    :disabled="contactSubmitLoading || (validation ? !!Object.keys(validation.errors).length : false)"
                                    type="primary"
                                    native-type="submit"
                                    @mouseenter="validation ? validation.validate() : null"
                                    @click="contactOrganisation">
                                    <span v-if="!contactSubmitLoading">Absenden</span>
                                    <span v-else><FontAwesomeIcon
                                        spin
                                        icon="circle-notch" /></span>
                                </BaseButton>
                            </div>
                        </VForm>
                    </div>
                </BaseModal>

                <BaseButton
                    v-if="!applicationDataId"
                    :disabled="nextLoading || (validation ? !!Object.keys(validation.errors).length : false)"
                    color="primary"
                    class="mt-5 w-full"
                    size="lg"
                    native-type="submit"
                    @mouseenter="validation ? validation.validate() : null"
                    @click="onSubmit">
                    <span
                        v-if="nextLoading"
                        class="mr-2"><FontAwesomeIcon
                            spin
                            icon="circle-notch" /></span>
                    <span v-if="!applicationDataId">{{ $t('application.next') }}</span>
                </BaseButton>
            </div>
        </VForm>
    </div>
</template>

<script>
import applicationFields from "@/mixins/application/applicationFields";
import debounce from "lodash/debounce";
import cloneDeep from "lodash/cloneDeep";
import JuleicaOrganisationsMap from "@/components/pages/application/JuleicaOrganisationsMap";
import Swal from "sweetalert2";
import federalStates from "@/mixins/federalStates";
import dateConvert from "@/mixins/dateConvert";
import applicationMain from "@/mixins/application/applicationMain";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "StepEducation",
    components: {FontAwesomeIcon, JuleicaOrganisationsMap},
    mixins: [applicationFields, federalStates, dateConvert, applicationMain],
    data() {
        return {
            useMapMode: false,
            search: "",
            selectOptions: [],
            showUnknownOrganisation: false,
            nextLoading: false,
            showContactOrganisationModal: false,
            contactSubject: null,
            contactMessage: null,
            contactSubmitLoading: false,
            notification: useNotification()
        }
    },

    computed: {
        showMoreHint() {
            return (this.search.length === 3 && this.selectOptions.length === 7) || (this.search.length === 4 && this.selectOptions.length === 20)
        },

        federalStateId() {
            if (this.unknownOrganisationMail !== null) {
                return this.federal_state_id
            } else if (this.organisation !== null) {
                return this.organisation.federal_state_id
            }
        }
    },

    watch: {
        'organisation': function () {
            if (this.organisation !== null) {
                this.showUnknownOrganisation = false
                this.federal_state_id = this.$get(this.organisation, "federal_state_id", 0)
            }
        },

        'federalStateId': function () {
            if (this.$get(this.federalStates[this.federalStateId], 'config[6].options', null) != null && this.educationDescription7 !== 'L') {
                this.educationDescription7 = 'G'
            }
        }
    },

    mounted() {
        this.showUnknownOrganisation = this.unknownOrganisationMail;

        if (this.organisation !== null) {
            this.showUnknownOrganisation = false
            this.federal_state_id = this.$get(this.organisation, "federal_state_id", 0)
        }
    },

    methods: {
        async onSubmit() {

            if (this.applicationDataId) {
                // send revised application

                // check birthdate
                this.date_of_birth = this.convertToLaravelDate(this.date_of_birth);

                this.submitJuleicaApplication();

            } else {
                // go to next page
                this.nextLoading = true
                if (this.$get(this.organisation, "id", 0) > 0) this.organisation = (await $lara.get(this.$apiRoute('spa.frontend.organisation.show'), {params: {id: this.organisation.id}})).data
                this.nextLoading = false
                this.$emit('nextPage')
            }
        },
        onSearchOrganisation(search, loading) {
            this.search = search;
            if (search.length <= 2) {
                return;
            }
            loading(true)
            this.onSearchOrganisationDebounced(search, loading)
        },
        onSearchOrganisationDebounced: debounce(async function (search, loading) {
            try {
                loading(true)
                this.selectOptions = (await $lara.get(this.$apiRoute('spa.frontend.organisations.index'),
                                                      {
                                                          params: {
                                                              search: search
                                                          }
                                                      }
                )).data
                loading(false)
            } catch (error) {
                console.log(error);
                loading(false)
            }
        }, 600),
        addUploadedFile(event) {
            let documentsCopy = []
            if (this.uploadedDocuments) {
                documentsCopy = cloneDeep(this.uploadedDocuments);
            }
            documentsCopy.push({document: event[1].data, uuid: event[0].upload.uuid})
            this.uploadedDocuments = documentsCopy;
        },
        removeFile(event) {
            let uploadedDocumentsCopy = []
            if (this.uploadedDocuments) {
                uploadedDocumentsCopy = cloneDeep(this.uploadedDocuments);
            }
            this.uploadedDocuments = uploadedDocumentsCopy.filter(function (uploadedDocuments) {
                return uploadedDocuments.uuid !== event[0].upload.uuid
            })
        },
        async rejectCards() {
            Swal.fire({
                title: 'Sicher ablehnen?',
                customClass: {
                    confirmButton: 'btn btn-danger mr-2',
                    cancelButton: 'btn btn-outline-secondary'
                },
                buttonsStyling: false,
                showCancelButton: true,
                cancelButtonText: this.$t("cancel"),
                confirmButtonText: 'Juleica-Antrag ablehnen'
            }).then((result) => {
                if (result.value) {
                    this.rejectCard()
                }
            });
        },
        async contactOrganisation() {
            try {
                const isValid = await this.$refs.contactOrganisationFormValidator.validate();
                if (!isValid) return

                this.contactSubmitLoading = true;

                const response = await $lara.post(this.$apiRoute('spa.frontend.organisationContact.store'), {
                    body: {
                        subject: this.contactSubject,
                        message: this.contactMessage,
                        organisation_id: this.organisation.id,
                        applicationData: this.applicationData
                    }
                })

                this.contactSubmitLoading = false;

                this.showContactOrganisationModal = false;

                this.notification.success('Nachricht wurde erfolgreich versendet')
            } catch (error) {

                if (error?.response?.status === 422) this.notification.error('Fehler beim Verarbeiten der Eingaben')

                this.contactSubmitLoading = false;
            }
        }
    }
}
</script>

<style  scoped>
.search-buttons {
    height: 50px;
    width: 130px;
    color: gray;
    border-color: lightgray;
    border-style: solid;
    border-width: 2px;
}

.search-buttons.active {
    border-color: $primary;
    color: $primary;
}

:deep .vs__search {
    border-color: transparent;
}

:deep .vs__selected-options {
    flex-wrap: nowrap;
}

.mt-6 {
    margin-top: 4rem;
}
</style>
