export default {
    getCountries({commit, state}) {
        const {$apiRoute} = useNuxtApp()
        const {t: $t} = useI18n()

        try {

            // prefetch, because we need the countries Data very early...
            if (localStorage.getItem('countries') && localStorage.getItem('countries') !== undefined && localStorage.getItem('countries') != 'undefined') {
                commit('setCountries', JSON.parse(localStorage.getItem('countries')))
            }

            // load countries
            return $lara.get($apiRoute('spa.countries.index')).then(response => {
                let countriesTranslated = [];
                for (let country in response) {
                    countriesTranslated.push({'code': country, 'name': $t('countries.' + country)})
                }
                commit('setCountries', countriesTranslated.sort((a, b) => a.name.localeCompare(b.name)))
            });

        } catch (error) {
            throw(error)
        }
    }
}
