import { Line } from "vue-chartjs";
import { Bar } from "vue-chartjs";
import { Pie } from "vue-chartjs";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    ArcElement
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    PointElement,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    ArcElement,
);

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('line-chart', Line, {})
    nuxtApp.vueApp.component('bar-chart', Bar, {})
    nuxtApp.vueApp.component('pie-chart', Pie, {})
})
