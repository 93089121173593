import {PiniaPluginContext} from 'pinia'
import {defineNuxtPlugin, useNuxtApp} from "nuxt/app";

function MyPiniaPlugin({store}: PiniaPluginContext) {
    store.$subscribe((mutation) => {
        // react to store changes
        console.log(`[🍍 ${mutation.storeId}]: ${mutation.type} ${mutation.events}.`)

        if (process.client) {
            const nuxtApp = useNuxtApp()

            try {
                if (mutation.storeId === 'auth') {
                    //this abilitySingelton has been injected by the casl Plugin in plugins/casl
                    const ability = nuxtApp.$abilitySingleton

                    if (store.$state.abilities) {
                        ability.update(store.$state.abilities.map(function (ability) {
                            return {
                                action: ability.action,
                                subject: ability.subject
                            }
                        }))
                    } else {
                        ability.update([])
                    }
                }
            } catch (e) {
                console.log(e)
            }
        }
    })

    // Note this has to be typed if you are using TS
    return {creationTime: new Date()}
}

export default defineNuxtPlugin(({$pinia}) => {
    $pinia.use(MyPiniaPlugin)
})