<template>
    <div class="p-4 border-[5px] border-secondary rounded-lg text-black mt-24">
        <div class="grid grid-cols-6 gap-3">
            <div class="col-span-1">
                <img
                    class="h-16"
                    src="~assets/bayern_emblem.png">
            </div>
            <div class="col-span-5">
                <h2 class="text-left">
                    Bayerische <br> Ehrenamtskarte
                </h2>
            </div>
        </div>
        <div v-if="!submitted">
            <div class="text-left mt-5">
                Du kannst mit diesem Antrag auch die Bayerische Ehrenamtskarte bestellen. Sie wird an die angegebene
                Adresse versendet.<br>
                Mit deiner persönlichen Ehrenamtskarte bekommst du verschiedene Vergünstigungen, z.B. in Museen,
                Freizeitstätten oder Jugendherbergen.
            </div>

            <div class="text-left mt-3">
                <a
                    class="underline"
                    target="_blank"
                    href="https://www.ehrenamtskarte.bayern.de">Infos zur Bayerischen Ehrenamtskarte</a>
                <VForm
                    v-slot="validation"
                    ref="formValidatorBayern"
                    as="div"
                    :keep-values="true">
                    <BaseCheckbox
                        v-model="privacy"
                        :rules="{required: true}"
                        name="Datenschutz"
                        required
                        class="mt-3">
                        Ich möchte die Bayerische Ehrenamtskarte erhalten. Ich willige ein, dass meine im Rahmen
                        der Antragstellung erhobenen Daten hierfür verarbeitet und an die beim Landratsamt oder
                        bei der kreisfreien Stadt meines Wohnortes zuständige Stelle zur Ausstellung der
                        Ehrenamtskarte weitergeleitet werden. Weitere Informationen finden Sie in unserer
                        <a
                            class="underline"
                            href="/datenschutz"
                            target="_blank">Datenschutzerklärung</a>.*
                    </BaseCheckbox>

                    <!--<span>Deine Daten gehen an: {{ fsExtension.usedBy[0].parameter_1 }}</span>-->

                    <BaseButton
                        color="secondary"
                        size="lg"
                        class="mt-3 w-full mb-2"
                        :disabled="submitLoading || (validation ? !!Object.keys(validation.errors).length : false)"
                        @mouseenter="validation ? validation.validate() : null"
                        @click="onSubmit()">
                        <span v-if="!submitLoading">Bayerische Ehrenamtskarte jetzt kostenfrei und ohne Verbindlichkeiten bestellen</span>
                        <span v-else><FontAwesomeIcon
                            spin
                            icon="circle-notch" /></span>
                    </BaseButton>
                </VForm>
            </div>
        </div>
        <div
            v-else
            class="mt-4 mb-2">
            <FontAwesomeIcon
                icon="check"
                class="text-success" />
            Bayerische Ehrentamtskarte erfolgreich beantragt
        </div>
    </div>
</template>

<script>
import applicationMain from '~/mixins/application/applicationMain'

export default {
    name: "FsExtensionBayernVolunteerCards",
    components: {},
    mixins: [applicationMain],
    props: {
        mainNamespace: {
            type: String
        },
        submitted: {
            type: Boolean
        },
        name: {
            type: String
        },
        fsExtension: {
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            submitLoading: false,
            privacy: false,
            namespace: 'bayernVolunteerCard',
            notification: useNotification()
        }
    },
    computed: {},
    methods: {
        async onSubmit() {
            this.submitLoading = true


            $lara.post(this.$apiRoute('spa.frontend.fsExtensionBayernVolunteerCard.store'), {
                body: {'card_id': this.submitResponse.card_id, 'token': this.submitResponse.token}
            }).then(response => {

                this.submitLoading = false;

                this.emitOnSubmit()

                this.notification.success(this.$t(this.mainNamespace + "." + this.namespace + ".savedSuccessfully"))

            }).catch(error => {
                if (error.response && error.response.data.errors !== undefined) this.$refs.formValidator.setErrors(error.response.data.errors);
                this.submitLoading = false
                this.notification.error(error.response !== undefined ? this.$t('axios.' + error.response.data.message.replace(".", "")) : this.$t('axios.errorWhileFetchingData'))
            });
        },

        emitOnSubmit(event) {
            this.$emit('onSubmit')
        }
    }
}
</script>

<style  scoped>
    .fs-extension-div {
        padding: 1rem;
        border: 5px solid #FED42F;
        border-radius: 1rem;
        color: black;
    }

    img {
        max-width: 100%
    }

    .text-align-left {
        text-align: left;
    }

    .submit-button {
        background-color: $yellow;
    }
</style>
