export default {
    currentStepName: (state) => {
        if (state.applicationSubmitLoading) {
            return "stepApplicationSubmitLoading"
        } else if (state.applicationSubmitResponse !== null) {
            if (state.applicationSubmitResponse && state.applicationSubmitResponse.status === 200) {
                return "stepApplicationSubmitSuccess"
            } else {
                return "stepApplicationSubmitError"
            }
        } else {
            return state.steps[state.currentStep]
        }
    },

    submitResponse: (state) => {
        return state.applicationSubmitResponse !==null ? state.applicationSubmitResponse.data:null
    },

    userCanTryToRetryAfterFailedSubmit: (state) => {
        return !state.applicationSubmitResponse || state.responseStatusCodesWhichMayAllowARetry.includes(state.applicationSubmitResponse.status);
    },

    submitApplicationError: (state) => {
        return state.submitApplicationError
    },

    showAnswers: (state) => {
        return state.showAnswers
    }
}
