export const usePageTitle = () => {
    const pageTitle = <Ref<string | null>>useState('pageTitle', () => null)

    if (getCurrentInstance()?.type.__name !== 'app') {
        // Reset page title on route change
        /*onBeforeRouteLeave(() => {
            pageTitle.value = null
        })*/
    }

    return pageTitle
}