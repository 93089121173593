import { default as _91_46_46_46slug_93A4weRjwSEyMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/[...slug].vue?macro=true";
import { default as editRrXHysm1vgMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/application/edit.vue?macro=true";
import { default as indexyqPcP1uTexMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/application/index.vue?macro=true";
import { default as indexAxNQOVdtHhMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/auth/index.vue?macro=true";
import { default as indexMkTfvtBM4RMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/index.vue?macro=true";
import { default as index4v5EUb0M8kMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/myjuleica/index.vue?macro=true";
import { default as usermj1DscdrVTMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/myjuleica/user.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93A4weRjwSEyMeta?.name ?? "slug",
    path: _91_46_46_46slug_93A4weRjwSEyMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93A4weRjwSEyMeta || {},
    alias: _91_46_46_46slug_93A4weRjwSEyMeta?.alias || [],
    redirect: _91_46_46_46slug_93A4weRjwSEyMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: editRrXHysm1vgMeta?.name ?? "application-edit",
    path: editRrXHysm1vgMeta?.path ?? "/application/edit",
    meta: editRrXHysm1vgMeta || {},
    alias: editRrXHysm1vgMeta?.alias || [],
    redirect: editRrXHysm1vgMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/application/edit.vue").then(m => m.default || m)
  },
  {
    name: indexyqPcP1uTexMeta?.name ?? "application",
    path: indexyqPcP1uTexMeta?.path ?? "/application",
    meta: indexyqPcP1uTexMeta || {},
    alias: indexyqPcP1uTexMeta?.alias || [],
    redirect: indexyqPcP1uTexMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexAxNQOVdtHhMeta?.name ?? "auth",
    path: indexAxNQOVdtHhMeta?.path ?? "/auth",
    meta: indexAxNQOVdtHhMeta || {},
    alias: indexAxNQOVdtHhMeta?.alias || [],
    redirect: indexAxNQOVdtHhMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexMkTfvtBM4RMeta?.name ?? "index",
    path: indexMkTfvtBM4RMeta?.path ?? "/",
    meta: indexMkTfvtBM4RMeta || {},
    alias: indexMkTfvtBM4RMeta?.alias || [],
    redirect: indexMkTfvtBM4RMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index4v5EUb0M8kMeta?.name ?? "myjuleica",
    path: index4v5EUb0M8kMeta?.path ?? "/myjuleica",
    meta: index4v5EUb0M8kMeta || {},
    alias: index4v5EUb0M8kMeta?.alias || [],
    redirect: index4v5EUb0M8kMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/myjuleica/index.vue").then(m => m.default || m)
  },
  {
    name: usermj1DscdrVTMeta?.name ?? "myjuleica-user",
    path: usermj1DscdrVTMeta?.path ?? "/myjuleica/user",
    meta: usermj1DscdrVTMeta || {},
    alias: usermj1DscdrVTMeta?.alias || [],
    redirect: usermj1DscdrVTMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20241212141141/client-frontend/pages/myjuleica/user.vue").then(m => m.default || m)
  }
]