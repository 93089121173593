<template>
    <JuleicaBaseCard>
        <div class="flex md:flex-row flex-col">
            <div class="flex-grow me-4">
                <h2 v-if="loading">
                    <FontAwesomeIcon
                        icon="circle-notch"
                        spin />
                </h2>
                <div
                    v-else
                    class="flex gap-1">
                    <h2>
                        {{ heading }}
                    </h2>
                    <BasePopover
                        v-if="filterType === 'ags'"
                        size="sm">
                        <template #trigger>
                            <FontAwesomeIcon
                                class="mt-1.5"
                                icon="question-circle" />
                        </template>
                        <template #default>
                            <span class="text-neutral-500 dark:text-gray-300 mt-2">
                                {{ $t('statistics.helpText.agsTable') }}
                            </span>
                        </template>
                    </BasePopover>
                    <!--<BTooltip
                            target="question-circle"
                            :triggers="['hover', 'click']">
                            {{ $t('statistics.helpText.agsTable') }}
                        </BTooltip>-->
                </div>
            </div>

            <div
                v-if="$get(model, 'agsLevel', '0') === '2' && !loading && filterType === 'ags'"
                class="me-2 pr-3 pt-2 pb-2">
                <BaseCheckbox v-model="showPerResidentNumbers">
                    pro 100.000 Einwohner
                </BaseCheckbox>
            </div>

            <div class="me-2">
                <BaseInput
                    v-model="filter"
                    :full-width="false">
                    <BaseSelect v-model="filter">
                        <optgroup label="Abgeschlossene Anträge">
                            <option value="ready-newOrExtension">
                                Abgeschlossene Anträge - Neuantrag oder Verlängerungsantrag
                            </option>
                            <option value="ready-age">
                                Abgeschlossene Anträge - Alter
                            </option>
                        </optgroup>
                        <optgroup label="Gültige Karten">
                            <option value="valid-age">
                                Gültige Karten - Alter
                            </option>
                        </optgroup>
                    </BaseSelect>
                </BaseInput>
            </div>
        </div>

        <JuleicaTable>
            <thead>
                <tr>
                    <JuleicaTableHead>{{ heading }}</JuleicaTableHead>
                    <JuleicaTableHead>{{ $t('statistics.entity.' + filterSection) }}</JuleicaTableHead>
                    <JuleicaTableHead
                        v-for="(ageGroup, index) in $get(model, 'ageGroups', 'Alter')"
                        v-if="filterValue === 'age' && activeChart === 'table'"
                        :key="index"
                        class="hidden sm:table-cell">
                        {{ ageGroup }}
                    </JuleicaTableHead>
                    <JuleicaTableHead
                        v-if="filterValue === 'newOrExtension' && activeChart === 'table'"
                        class="hidden sm:table-cell">
                        {{ $t('statistics.table.new') }}
                    </JuleicaTableHead>
                    <JuleicaTableHead
                        v-if="filterValue === 'newOrExtension' && activeChart === 'table'"
                        class="hidden sm:table-cell">
                        {{ $t('statistics.table.extension') }}
                        <FontAwesomeIcon
                            id="info-circle-table-newOrExtension"
                            icon="info-circle" />
                        <!--<BTooltip
                            target="info-circle-table-newOrExtension"
                            triggers="hover">
                            {{ $t('statistics.helpText.newOrExtension') }}
                        </BTooltip>-->
                    </JuleicaTableHead>
                    <JuleicaTableHead
                        v-if="useCharts"
                        class="text-right min-w-24">
                        <FontAwesomeIcon
                            icon="table"
                            :class="{'text-primary': activeChart === 'table'}"
                            class="cursor-pointer"
                            @click="activeChart = 'table'" />
                        <span v-if="useBarChart">
                            | <FontAwesomeIcon
                                icon="chart-bar"
                                :class="{'text-primary': activeChart === 'bar'}"
                                class="cursor-pointer"
                                @click="activeChart = 'bar'" />
                        </span>
                        <span v-if="useLineChart">
                            | <FontAwesomeIcon
                                icon="chart-line"
                                :class="{'text-primary': activeChart === 'line'}"
                                class="cursor-pointer"
                                @click="activeChart = 'line'" />
                        </span>
                    </JuleicaTableHead>
                </tr><tr />
            </thead>
            <JuleicaTableBody v-if="loading">
                <JuleicaTableBodyRow
                    v-for="n in 5"
                    :key="n">
                    <JuleicaTableColumn
                        :colspan="10"
                        loading />
                </JuleicaTableBodyRow>
            </JuleicaTableBody>
            <JuleicaTableBody v-else-if="$get(model[filterSection], 'now.'+filterType, 0) === 0">
                <JuleicaTableBodyRow>
                    <JuleicaTableColumn :colspan="10">
                        Keine Einträge
                    </JuleicaTableColumn>
                </JuleicaTableBodyRow>
            </JuleicaTableBody>
            <JuleicaTableBody v-else-if="activeChart === 'table'">
                <JuleicaTableBodyRow
                    v-for="(row, index) in rows"
                    :key="index">
                    <JuleicaTableColumn>{{ row.name }}</JuleicaTableColumn>
                    <!-- count-->
                    <JuleicaTableColumn v-if="showPerResidentNumbers">
                        {{ row.countPerResident }} <small>pro 100.000 Einwohner</small>
                    </JuleicaTableColumn>
                    <JuleicaTableColumn v-else>
                        {{ addThousandSeparator(row.count) }}
                        <StatisticCountDevelopment
                            v-if="filterType === 'ags'"
                            :model="model"
                            :loading="loading"
                            :entity="filterSection"
                            :data-section="filterType+'.'+index+'.count'"
                            small-text />
                    </JuleicaTableColumn>
                    <!-- age groups-->
                    <JuleicaTableColumn
                        v-if="filterValue === 'age' && (typeof row.ageGroups === 'string' || row.ageGroups instanceof String)"
                        class="hidden sm:table-cell"
                        :colspan="$get(model, 'ageGroups', []).length">
                        <div class="flex gap-1">
                            {{ row.ageGroups }}
                            <BasePopover
                                size="sm">
                                <template #trigger>
                                    <FontAwesomeIcon
                                        class="mt-0.5"
                                        icon="question-circle" />
                                </template>
                                <template #default>
                                    <span class="text-neutral-500 dark:text-gray-300 mt-2">
                                        {{ $t('statistics.helpText.ageGroupNotEnoughData') }}
                                    </span>
                                </template>
                            </BasePopover>
                        <!--<BTooltip
                            :target="'question-circle-age-' + index"
                            triggers="hover">
                            {{ $t('statistics.helpText.ageGroupNotEnoughData') }}
                        </BTooltip>-->
                        </div>
                    </JuleicaTableColumn>
                    <JuleicaTableColumn
                        v-for="(ageGroup, index) in row.ageGroupsPerResident"
                        v-if="filterValue === 'age' && showPerResidentNumbers && row.ageGroups !== 'Nicht ausreichend Daten gefunden.'"
                        :key="index"
                        class="hidden sm:table-cell">
                        {{ ageGroup }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn
                        v-for="(ageGroup, index) in row.ageGroups"
                        v-if="filterValue === 'age' && !showPerResidentNumbers && row.ageGroups !== 'Nicht ausreichend Daten gefunden.'"
                        :key="index"
                        class="hidden sm:table-cell">
                        {{ addThousandSeparator(ageGroup) }}
                    </JuleicaTableColumn>
                    <!-- new or extension-->
                    <JuleicaTableColumn
                        v-if="filterValue === 'newOrExtension' && showPerResidentNumbers"
                        class="hidden sm:table-cell">
                        {{ row.newOrExtensionPerResident.new }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn
                        v-else-if="filterValue === 'newOrExtension'"
                        class="hidden sm:table-cell">
                        {{ addThousandSeparator(row.newOrExtension.new) }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn
                        v-if="filterValue === 'newOrExtension' && showPerResidentNumbers"
                        class="hidden sm:table-cell">
                        {{ row.newOrExtensionPerResident.extension }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn
                        v-else-if="filterValue === 'newOrExtension'"
                        class="hidden sm:table-cell">
                        {{ addThousandSeparator(row.newOrExtension.extension) }}
                    </JuleicaTableColumn>
                    <JuleicaTableColumn v-if="useCharts" />
                </JuleicaTableBodyRow>
            </JuleicaTableBody>
        </JuleicaTable>
        <template v-if="!loading && $get(model[filterSection], 'now.'+filterType, 0) !== 0">
            <JuleicaBaseCard
                v-if="activeChart === 'bar' && !loading"
                :rounded="false"
                min-padding>
                <BarChart
                    :data="chartData"
                    :options="{responsive: true,maintainAspectRatio: false}"
                    style="height: 400px" />
            </JuleicaBaseCard>
            <JuleicaBaseCard
                v-else-if="activeChart === 'line' && !loading"
                :rounded="false"
                :min-padding="!loadingLineCharts">
                <div
                    v-if="loadingLineCharts"
                    class="card-body">
                    <FontAwesomeIcon
                        icon="circle-notch"
                        spin />
                    Zeitlicher Verlauf wird geladen...
                </div>
                <div
                    v-else-if="Object.keys(dates).length === 0"
                    class="card-body">
                    {{ $t('statistics.helpText.lineChartToLongTimeInterval') }}
                </div>
                <LineChart
                    v-else
                    :data="lineChartData"
                    :options="lineChartOptions"
                    style="height: 400px" />
            </JuleicaBaseCard>
        </template>
    </JuleicaBaseCard>
</template>

<script>
import {Line, Bar} from 'vue-chartjs'
import StatisticCountDevelopment from "./statisticCountDevelopment";

export default {
    name: "StatisticTable",
    components: {StatisticCountDevelopment, LineChart: Line, BarChart: Bar},
    props: {
        model: {
            type: Object,
            required: true
        },
        filterType: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        loadingLineCharts: {
            type: Boolean,
            required: true
        },
        charts: {
            type: Array,
            default: [],
            description: 'List of charts that should get shown. Available charts: bar, line'
        }
    },
    data() {
        return {
            filter: 'ready-age',
            agsLevels: {
                2: 'Landesebene',
                5: 'Kreisebene',
                8: 'Gemeindeebne'
            },
            showPerResidentNumbers: false,
            activeChart: 'table',
            backgroundColors: ['#FED42F', '#f39c12', '#d35400', '#e74c3c', '#c0392b', '#9f3126', '#2ecc71', '#27ae60', '#1abc9c', '#16a085', '#3498db', '#34495e'],
            lineChartOptions: {
                responsive: true,
                maintainAspectRatio: false
            }
        }
    },
    computed: {
        filterSection() {
            return this.filter.split('-')[0]
        },
        filterValue() {
            return this.filter.split('-')[1]
        },
        heading() {
            if (this.filterType === 'ags') {
                return 'Geografische Lage'
            } else {
                return this.$t('statistics.filterType.' + this.filterType)
            }
        },
        dates() {
            const object = this.model[this.filterSection] ? this.$get(this.model[this.filterSection], 'dates', {}) : {}
            return Object.keys(object).sort().reduce(
                (obj, key) => {
                    obj[key] = object[key];
                    return obj;
                },
                {}
            )
        },
        rows() {
            const object = this.loading || !this.model[this.filterSection] ? {} : this.$get(this.model[this.filterSection].now, [this.filterType], {})
            return Object.keys(object).sort().reduce(
                (obj, key) => {
                    obj[key] = object[key];
                    return obj;
                },
                {}
            )
        },
        rowsLastYear() {
            const object = this.loading || !this.model[this.filterSection] ? {} : this.$get(this.model[this.filterSection].lastYear, [this.filterType], {})
            return Object.keys(object).sort().reduce(
                (obj, key) => {
                    obj[key] = object[key];
                    return obj;
                },
                {}
            )
        },
        useCharts() {
            return this.charts.length > 0 && Object.keys(this.rows).length <= 20
        },
        useBarChart() {
            return this.charts.includes('bar')
        },
        useLineChart() {
            return this.charts.includes('line')
        },
        chartData() {
            const datasets = []
            const labels = []
            const data = []
            const dataLastYear = []
            const rows = Object.entries(this.rows)

            rows.forEach(row => {
                labels.push(row[1].name)
                data.push(this.showPerResidentNumbers ? row[1].countPerResident : row[1].count)
                if (!this.model.noTimeIntervalLastYear) dataLastYear.push(this.showPerResidentNumbers ? this.rowsLastYear[row[0]]?.countPerResident : this.rowsLastYear[row[0]]?.count)
            })

            datasets.push(
                {
                    label: 'Aktueller Zeitraum',
                    backgroundColor: this.backgroundColors,
                    borderColor: this.backgroundColors,
                    data: data
                }
            )

            if (!this.model.noTimeIntervalLastYear) {
                datasets.push(
                    {
                        label: 'Vorjahr',
                        backgroundColor: this.backgroundColors,
                        borderColor: this.backgroundColors,
                        data: dataLastYear
                    }
                )
            }

            return {
                labels: labels,
                datasets: datasets
            }
        },
        lineChartData() {
            const labels = []
            const dates = Object.entries(this.dates)
            const rows = Object.entries(this.rows)
            const agsObject = {}

            let count = 0
            //get all ags in array
            rows.forEach(row => {
                agsObject[row[0]] = {
                    label: row[1].name,
                    data: [],
                    backgroundColor: this.backgroundColors[count],
                    borderColor: this.backgroundColors[count]
                }
                count = (this.backgroundColors.length > count) ? count + 1 : 0
            })

            const agsArray = Object.entries(agsObject)
            //get all ags count for every date
            dates.forEach(date => {
                const dates = date[0].split('_')
                labels.push(this.$dayjs(dates[0]).format('DD.MM') + ' - ' + this.$dayjs(dates[1]).format('DD.MM.YY'))
                agsArray.forEach(ags => {
                    const dateAgs = this.$get(date[1], 'ags', [])
                    const count = this.showPerResidentNumbers ? this.$get(dateAgs[ags[0]], 'countPerResident', 0) : this.$get(dateAgs[ags[0]], 'count', 0)
                    agsObject[ags[0]].data.push(count)
                })
            })

            return {
                labels: labels,
                datasets: Object.values(agsObject)
            }
        },
    },

    methods: {
        addThousandSeparator(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
}
</script>

<style>
.dots {
    margin: 0 5px -8px 0;
    display: inline-block;
}

.dots-type-po {
    .dot-color {
        background: $yellow;
    }
}

.dots-type-fo {
    .dot-color {
        background: $pink;
    }
}

.dot {
    width: 8px;
    height: 8px;
    background: $gray-200;
    border-radius: 4px;
    padding: 2px;
}

.label-question-circle {
    font-size: 13px;
}

.min-w-90 {
    min-width: 90px;
}
</style>
