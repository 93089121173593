<template>
    <div class="flex justify-center">
        <div class="my-4 w-5/6 flex justify-center">
            <div class="h-12 flex-grow flex justify-center md:flex-col md:h-inherit">
                <div
                    v-for="(step, index) in steps"
                    :key="step"
                    class="flex items-center md:flex-col md:items-start flex-grow first:flex-grow-0">
                    <div
                        v-if="index !== 0"
                        class="flex-grow bg-gray-500 h-0.5 transition-[background-color] duration-500 ease-[ease] delay-0 md:transform md:rotate-z-90 md:w-5 md:ml-1.5"
                        :class="{'bg-primary': currentStep >= index}" />
                    <div
                        class="w-6 h-6 rounded-[50%] border-2 border-solid border-gray-500 transition-[background-color] duration-500 ease-[ease] delay-0 relative p-0.5 md:my-3 md:w-8 md:h-8 md:py-[3px] md:px-1.5"
                        :class="{'cursor-pointer': doneTillStep >= index && !deactivateClick, 'border-primary text-primary': currentStep >= index}"
                        @click="goToStep(index)">
                        <FontAwesomeIcon
                            icon="check"
                            class="text-[75%] translate-x-[2px] translate-y-[-4px] md:transform-none md:text-size-inherit"
                            :class="{'invisible': index >= currentStep}" />
                        <div
                            class="w-24 -ml-11 text-center text-gray-700 text-[80%] md:absolute md:left-10 md:top-0.5 md:w-52 md:ml-0 md:text-left md:text-base md:font-medium"
                            :class="{'text-primary': currentStep === index}"
                            :tabindex="doneTillStep >= index && !deactivateClick ? 0 : -1"
                            v-html="$t('application.'+ step)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ApplicationSteps",
    props: {
        steps: {required: true, type: Array},
        currentStep: {required: true, type: Number},
        doneTillStep: {required: true, type: Number},
        deactivateClick: {default: false, type: Boolean}
    },
    methods: {
        goToStep(index) {
            if (this.doneTillStep >= index && !this.deactivateClick) {
                this.$emit('stepChoosen', index);
            }
        }
    }
}
</script>


<style  scoped>

</style>