<template>
    <span>
        <span
            v-if="!$get(model, 'noTimeIntervalLastYear', false)"
            :id="entity+dataSection+'CountDevelopment'"
            class="font-bold text-base"
            :class="{'text-[90%]': smallText}">
            <FontAwesomeIcon
                v-if="countDevelopment < -5"
                icon="arrow-right"
                class="rotate-45 text-danger" />
            <FontAwesomeIcon
                v-else-if="countDevelopment > 5"
                icon="arrow-right"
                class="-rotate-45 text-success" />
            <FontAwesomeIcon
                v-else
                icon="arrow-right" />
            {{ countDevelopment }}%
        </span>
        <!--<BTooltip
            :target="entity+dataSection+'CountDevelopment'"
            placement="right">
            Im Vergleich zum gleichen Zeitraum im Vorjahr<br>
            Vorjahr: {{ lastYearCount }}
        </BTooltip>-->
    </span>
</template>

<script>
export default {
    name: "StatisticCountDevelopment",
    props: {
        model: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        entity: {
            type: String,
            required: true
        },
        dataSection: {
            type: String,
            default: 'count'
        },
        smallText: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        data() {
            return this.$get(this.model, [this.entity], null)
        },
        lastYearCount() {
            return this.$get(this.data, 'lastYear.' + this.dataSection, 0)
        },
        nowCount() {
            return this.$get(this.data, 'now.' + this.dataSection, 0)
        },
        countDevelopment() {
            return this.lastYearCount === 0 ? 0 : Math.round(100 / this.lastYearCount * this.nowCount - 100)
        }
    }
}
</script>

<style>

</style>
