<template>
    <div>
        <div
            v-if="loading"
            class="flex flex-col items-center mt-4">
            <FontAwesomeIcon
                spin
                icon="circle-notch"
                size="3x"
                class="mt-5" />
        </div>
        <div v-else-if="statistic_questions.length === 0" />
        <div v-else-if="answerSubmited">
            <p class="text-center">
                Danke dass du dir kurz Zeit genommen hast, um die Fragen zu beantworten!
            </p>
        </div>
        <div v-else>
            <div
                v-if="!showAnswers"
                class="p-4 border-[5px] border-secondary rounded-lg text-black mt-24">
                <div class="grid grid-cols-6">
                    <div class="col-span-1">
                        <FontAwesomeIcon
                            icon="clipboard-list"
                            size="3x" />
                    </div>
                    <div class="col-span-5">
                        <h2 class="text-left">
                            Wir brauchen <br> deine Meinung!
                        </h2>
                    </div>
                </div>
                <div>
                    <div class="text-left mt-5">
                        Wir würden uns freuen, wenn du dir kurz Zeit nimmst, um ein paar Fragen zu beantworten! Mit
                        deiner Hilfe möchten wir den aktuellen Status quo des ehrenamtlichen Engagements in
                        Jugendverbänden ermitteln und herausfinden, mit welchen Herausforderungen ehrenamtliches
                        Engagement junger Menschen in Jugendverbänden konfrontiert ist. Besonders interessiert sind wir
                        daher an deinen Erfahrungen zur Juleica-Ausbildung und deinem ehrenamtlichen Engagement.
                    </div>

                    <div class="text-left mt-3">
                        <a
                            target="_blank"
                            class="underline underline-offset-2"
                            href="/datenschutz-forschungsprojekt">
                            Datenschutz
                        </a>
                    </div>
                </div>
                <BaseButton
                    size="lg"
                    class="mt-5 w-full mb-2"
                    @click="showAnswers = true">
                    Fragen jetzt beantworten
                </BaseButton>
            </div>
            <form v-else>
                <h2 class="text-3xl">
                    Wir brauchen deine Meinung!
                </h2>
                <ul
                    role="list"
                    class="divide-y divide-gray-100">
                    <Component
                        :is="statisticQuestionFieldName(statistic_question.question_variant)"
                        v-for="statistic_question in statistic_questions"
                        :key="statistic_question.id"
                        :options="statistic_question.options"
                        :question="statistic_question.question"
                        :hint="statistic_question.hint"
                        :index="statistic_question.id"
                        @child-to-parent="onChildInput" />
                </ul>
                <BaseButton
                    color="primary"
                    size="lg"
                    class="mt-5 w-full mb-3"
                    @click="saveAnswers()">
                    <span v-if="!submitLoading">Antworten abschicken</span>
                    <span v-else><FontAwesomeIcon
                        spin
                        icon="circle-notch" /></span>
                </BaseButton>
            </form>
        </div>
    </div>
</template>

<script>
import applicationMain from '~/mixins/application/applicationMain'
import StatisticText from "./statisticText";
import StatisticBoolean from "./statisticBoolean";
import StatisticCheck from "./statisticCheck";
import StatisticSelect from "./statisticSelect";
import StatisticGrades from "./statisticGrades";

export default {
    name: "Statistic",
    components: {StatisticText, StatisticBoolean, StatisticCheck, StatisticSelect, StatisticGrades},
    mixins: [applicationMain],
    props: {},
    data() {
        return {
            namespace: "statistic",
            statistic_questions: [],
            statistic_request: {},
            loading: true,
            submitLoading: false,
            answerSubmited: false,
            notification: useNotification()
        }
    },
    computed: {},
    activated() {
        console.log('activated');

    },

    mounted() {
        console.log('mounted');
        this.loadData()
    },
    methods: {
        async loadData() {
            console.log('loadData');
            this.loading = true;
            const response = await $lara.get(this.$apiRoute('spa.frontend.statisticQuestions.index'), {
                params: {
                    'federal_state_id': this.submitResponse.federal_state_id
                }
            }).catch(error => {
                console.log(error);
                if (error.response && error.response.status == "404") this.notification.error(this.$t('axios.404'))
            });
            console.log(response.data);

            this.statistic_questions = response.data;

            this.fillStatisticRequest(this.statistic_questions);
            this.loading = false
        },
        saveAnswers() {
            this.submitLoading = true

            $lara.post(this.$apiRoute('spa.frontend.statisticRequest.store'), {
                body: this.statistic_request
            }).catch(error => {
                if (error.response && error.response.data.errors !== undefined) this.$refs.formValidator.setErrors(error.response.data.errors);
                this.submitLoading = false
                this.notification.error(error.response !== undefined ? this.$t('axios.' + error.response.data.message.replace(".", "")) : this.$t('axios.errorWhileFetchingData'))
            });

            this.submitLoading = false;

            this.answerSubmited = true;

            this.notification.success(this.$t("statistic.savedSuccessfully"))
        },

        fillStatisticRequest(statisticQuestions) {
            if (this.statistic_request.answers === undefined) this.statistic_request.answers = [];
            this.statistic_request.federal_state_id = this.submitResponse.federal_state_id;
            this.statistic_request.country_id = this.submitResponse.country_id;
            this.statistic_request.zipcode = this.submitResponse.zipcode;
            this.statistic_request.birth_year = this.submitResponse.birth_year;
            this.statistic_request.card_id = this.submitResponse.card_id;
            statisticQuestions.forEach(element => this.forEachFunction(element));
        },

        statisticQuestionFieldName(question_variant) {
            if (question_variant == 'text') {
                return "statisticText"
            } else if (question_variant == 'boolean') {
                return "statisticBoolean"
            } else if (question_variant == 'checkBox') {
                return "statisticCheck"
            } else if (question_variant == 'selectBox') {
                return "statisticSelect"
            } else if (question_variant == 'grades') {
                return "statisticGrades"
            }
        },

        onChildInput(value, index) {
            this.statistic_request.answers[index]['answer'] = value;
        },

        forEachFunction(element) {
            this.statistic_request.answers[element.id] = {
                'statistic_question_id': element.id,
                'answer': 'Keine Antwort',
            }
        }

    }
}
</script>

<style>
.juleica-statistic-form {

    .juleica-form-group {
        margin-bottom: 0;
    }
}

.mt-100 {
    margin-top: 100px;
}

.statistic-div {
    padding: 1rem;
    border: 5px solid $yellow;
    border-radius: 1rem;
    color: black;
}

.text-align-left {
    text-align: left;
}

.yellow-submit-button {
    background-color: $yellow;
}


.statistic-form-control-label {
    color: black;
    font-weight: 600;
}
</style>
