import lodashGet from 'lodash/get'
import lodashDebounce from 'lodash/debounce'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.provide('log', () => (string) => {
        console.log(string)
    })

    // Gender i18n Helper
    // unfortunately there are no gendering options in i18n, so we use the pluralization for gendering
    // 0 neutral
    // 1 female
    // 2 male
    nuxtApp.provide('i18nGender', () => (gender) => {
        if (gender == "f") {
            return 1
        } else if (gender == "m") {
            return 2
        } else {
            return 0;
        }
    })

    nuxtApp.provide('get', lodashGet)

    nuxtApp.provide('delete', () => (array, index) => {
        array.splice(index, 1)
    })

    nuxtApp.provide('debounce', lodashDebounce)

    nuxtApp.provide('cookieExists', () => (cookieName) => {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return true;
            }
        }
        return false;
    })

    nuxtApp.provide('getCookie', () => (cookieName) => {
        const b = document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    })

    nuxtApp.provide('capitalizeFirstLetter', () => (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    })
})
