<template>
    <div>
        <div
            v-if="loading"
            class="flex flex-col items-center mt-4">
            <FontAwesomeIcon
                spin
                icon="circle-notch"
                size="3x"
                class="mt-10" />
        </div>
        <div v-else-if="fsExtensions.length === 0" />
        <div v-else>
            <ul>
                <Component
                    :is="fsExtension.name"
                    v-for="fsExtension in inactiveFsExtensions"
                    :key="fsExtension.id"
                    class="mt-5"
                    :index="fsExtension.id"
                    :main-namespace="namespace"
                    :name="fsExtension.name"
                    :submitted="submittedFsExtension[fsExtension.name]"
                    :fs-extension="fsExtension"
                    @onSubmit="setSubmittedFsExtension(fsExtension.name, true)" />
            </ul>
        </div>
    </div>
</template>

<script>
import applicationMain from '~/mixins/application/applicationMain'
import FsExtensionSachsenVolunteerCards from "./fsExtensionSachsenVolunteerCards";
import FsExtensionBayernVolunteerCards from "./fsExtensionBayernVolunteerCards";

export default {
    name: "FsExtension",
    components: {FsExtensionSachsenVolunteerCards, FsExtensionBayernVolunteerCards},
    mixins: [applicationMain],
    props: {},
    data() {
        return {
            namespace: "fsExtension",
            loading: false,
            submitLoading: false,
            fsExtensions: []
        }
    },
    computed: {
        inactiveFsExtensions() {
            return this.fsExtensions.filter(fsExtension => !fsExtension.active)
        },
    },
    activated() {
        this.loadData()
    },
    methods: {
        async loadData() {
            this.loading = true;

            this.fsExtensions = this.submitResponse.fs_extensions

            this.loading = false
        }
    }
}
</script>

<style  scoped>

</style>
