
export default {
    setApplicationData(state, {prop, value}){
        state.applicationData[prop] = value
    },

    setAllApplicationData(state, applicationData){
        state.applicationData = applicationData;
    },

    setCurrentStep(state, currentStep){
        state.currentStep = currentStep
        state.doneTillStep = currentStep > state.doneTillStep ? currentStep : state.doneTillStep
    },

    setDoneTillStep(state, doneTillStep){
        state.doneTillStep = doneTillStep
    },

    setSubmittedFsExtension(state, {prop, value}){
        state.submittedFsExtension[prop] = value
    },

    setApplicationSubmitLoading(state, submitLoading){
        state.applicationSubmitLoading = submitLoading;
    },

    setApplicationSubmitResponse(state, submitResponse){
        state.applicationSubmitResponse = submitResponse;
    },

    setSubmitApplicationError(state, error){
        state.submitApplicationError = error;
    },

    setShowAnswers(state, showAnswers){
        state.showAnswers = showAnswers;
    }
}
