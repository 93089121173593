export default {
    getFederalStates({commit, state}) {
        let {$apiRoute} = useNuxtApp()

        try {
            // prefetch, because we need the federalStates Data very early...
            if (localStorage.getItem('federalStates') && localStorage.getItem('federalStates') !== undefined && localStorage.getItem('federalStates') != 'undefined') {
                commit('setFederalStates', JSON.parse(localStorage.getItem('federalStates')))
            }

            return $lara.get($apiRoute('spa.frontend.federal_states.index')).then(response => {
                commit('setFederalStates', response)
            });

        } catch (error) {
            throw(error)
        }
    }
}
