<template>
    <ClientOnly>
        <PageElementWrapper>
            <div>
                <TableFilterStatisticBetweenDate
                    entity-name="Anträge"
                    :current-filter="listing.filter"
                    @addFilter="addFilter($event)"
                    @updateFilter="updateFilter($event)"
                    @removeFilterByName="removeFilterByName($event)"
                    @removeFilterByNameWithoutReload="removeFilterByNameWithoutReload($event)" />
            </div>

            <TableFilterQuick>
                <TableFilterQuickStatisticThisMonth
                    :current-filter="listing.filter"
                    @addFilter="addFilter($event)"
                    @updateFilter="updateFilter($event)" />
                <TableFilterQuickStatisticLastMonth
                    :current-filter="listing.filter"
                    @addFilter="addFilter($event)"
                    @updateFilter="updateFilter($event)" />
                <TableFilterQuickStatisticThisYear
                    :current-filter="listing.filter"
                    @addFilter="addFilter($event)"
                    @updateFilter="updateFilter($event)" />
                <TableFilterQuickStatisticLastYear
                    :current-filter="listing.filter"
                    @addFilter="addFilter($event)"
                    @updateFilter="updateFilter($event)" />
            </TableFilterQuick>

            <div class="mt-4">
                <small><FontAwesomeIcon icon="info-circle" /> {{ $t('statistics.helpText.ready') }}</small><br>
                <small><FontAwesomeIcon icon="info-circle" /> {{ $t('statistics.helpText.oldData') }}</small><br>
                <small><FontAwesomeIcon icon="info-circle" /> {{ $t('statistics.helpText.automaticExtension') }}</small>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-4">
                <div>
                    <StatisticCard
                        :model="model"
                        :loading="loading"
                        :loading-line-charts="loadingLineCharts"
                        entity="ready" />
                </div>
                <div>
                    <StatisticCard
                        :model="model"
                        :loading="loading"
                        :loading-line-charts="loadingLineCharts"
                        entity="valid"
                        :show-new-or-extension="false"
                        :show-started-by="false" />
                </div>
            </div>
            <div class="mt-5">
                <StatisticTable
                    :model="model"
                    filter-type="ags"
                    :loading="loading"
                    :loading-line-charts="loadingLineCharts"
                    :charts="['bar', 'line']" />
            </div>
        </PageElementWrapper>
    </ClientOnly>
</template>

<script>
import mixinTableFilter from "../../../mixins/statistic/tableFilter";
import mixinStoreListingSettings from "../../../mixins/statistic/storeListingSettings";
import StatisticCard from "../../../components/pages/statistics/statisticCard";
import StatisticTable from "../../../components/pages/statistics/statisticTable";
import TableFilterQuick from "../../../components/pages/statistics/Filter/TableFilterQuick";
import TableFilterQuickStatisticThisMonth from "../../../components/pages/statistics/Filter/StatisticThisMonth";
import TableFilterQuickStatisticLastMonth from "../../../components/pages/statistics/Filter/StatisticLastMonth";
import TableFilterQuickStatisticThisYear from "../../../components/pages/statistics/Filter/StatisticThisYear";
import TableFilterQuickStatisticLastYear from "../../../components/pages/statistics/Filter/StatisticLastYear";
import TableFilterStatisticBetweenDate from "../../../components/pages/statistics/Filter/StatisticBetweenDate";

export default {
    components: {
        TableFilterStatisticBetweenDate,
        TableFilterQuickStatisticLastYear,
        TableFilterQuickStatisticThisYear,
        TableFilterQuickStatisticLastMonth,
        TableFilterQuickStatisticThisMonth, TableFilterQuick, StatisticTable, StatisticCard},
    mixins: [mixinTableFilter, mixinStoreListingSettings],
    data() {
        return {
            loading: false,
            loadingLineCharts: false,
            model: {},
            defaultSettings: {
                filter: []
            },
            listing: {
                filter: []
            },
        }
    },
    mounted() {
        this.loadData()
    },
    methods: {
        async loadData() {
            this.loading = true;
            this.loadingLineCharts = true;

            let filterBetweenDate = this.listing.filter.find(element => element.name === 'TableFilterStatisticBetweenDate')

            if (!filterBetweenDate) {
                const newFilter = {
                    "name": 'TableFilterStatisticBetweenDate',
                    "type": "statisticBetweenDate",
                    "column": 'ready_at',
                    "operation": "betweenDate",
                    "value": this.$dayjs().startOf('M').format('YYYY-MM-DD') + ";" + this.$dayjs().format('YYYY-MM-DD'),
                    "label": null
                }

                this.addFilterWithoutFetch(newFilter)

                filterBetweenDate = newFilter
            }

            const response = await $lara.get(this.$apiRoute('spa.frontend.statistics.index'), {
                params: {
                    ...this.listing
                }
            }).catch(error => {
                console.log(error);
                const errorMessage = this.$t('axios.' + error.response.data.message.replace(".", ""))
                if (error.response.status == "403") this.$router.push(this.localePath('dashboard'))
                this.$toast.error(errorMessage, {duration: 3000})
            });

            console.log(response);

            this.model = response
            this.storeListingSettings()

            const filterBetweenDateValues = filterBetweenDate.value.split(';')
            const filterBetweenDateMonthDiff = this.$dayjs(filterBetweenDateValues[1]).diff(this.$dayjs(filterBetweenDateValues[0]), "month")

            this.loading = false

            console.log(this.loading);

            if (filterBetweenDateMonthDiff <= 60) {

                const responseLineCharts = await $lara.get(this.$apiRoute('spa.frontend.statistics.index'), {
                    params: {
                        ...this.listing,
                        loadLineCharts: 1
                    },
                }).catch(error => {
                    const errorMessage = this.$t('axios.' + error.response.data.message.replace(".", ""))
                    if (error.response.status == "403") this.$router.push(this.localePath('dashboard'))
                    this.$toast.error(errorMessage, {duration: 3000})
                });

                console.log(responseLineCharts);

                this.model.ready.dates = (responseLineCharts?.ready?.dates ?? [])
                this.model.valid.dates = (responseLineCharts?.valid?.dates ?? [])
                this.model.ready.datesLastYear = (responseLineCharts?.ready?.datesLastYear ?? [])
                this.model.valid.datesLastYear = (responseLineCharts?.valid?.datesLastYear ?? [])
                this.storeListingSettings()
            }

            this.loadingLineCharts = false
        },
    },
}
</script>
